<script lang="ts" setup>
import { useRoute } from "vue-router";

import NotificationCounter from "@/components/NotificationCounter.vue";

import useUiStore from "@/stores/common/ui";

import { ROUTES } from "@/router";
import type { IMenuItem } from "@/types/appTypes";

const props = defineProps<PropTypes>();

const uiStore = useUiStore();
const route = useRoute();

function getTypeOfNotificationCountForRouteName(routeName: string) {
  if (routeName == ROUTES.MESSAGES) return "UNREAD_MESSAGES";
  if (routeName == ROUTES.NOTIFICATIONS) return "UNREAD_MENTIONS_AND_REACTIONS";
  return "NONE";
}

function handleTap() {
  if (props.item.tapHandler) {
    props.item.tapHandler();
  }
  uiStore.closeSidebar();
}

type PropTypes = {
  item: IMenuItem;
  expanded: boolean;
};
</script>

<template>
  <template v-if="item.externalUrl">
    <a
      @click="handleTap"
      :href="props.item.externalUrl"
      :title="props.item.label + ' side menu item'"
      class="flex items-center p-4 space-x-3 text-base font-normal leading-6 transition duration-300 ease-out bg-opacity-0 rounded-md cursor-pointer overflow-x-clip focus:outline-none focus:text-dynamic-neutral-900 dark:focus:text-dynamic-neutral-200 bg-neutral-500 hover:bg-opacity-50"
    >
      <i :class="props.item.icon" class="ml-1 text-xl text-right fa-fw"></i>
      <div
        class="flex truncate transition-all duration-300 ease-out overflow-x-clip 2xl:ml-0 2xl:opacity-100"
        :class="{
          'ml-2 opacity-0': !props.expanded,
          'ml-0 opacity-100': props.expanded,
        }"
      >
        <span>{{ props.item.label }}</span>
      </div>
    </a>
  </template>
  <template v-else-if="props.item.route">
    <RouterLink
      :to="props.item.route"
      @click="handleTap"
      class="relative flex items-center justify-between pr-4 text-base font-normal leading-6 transition duration-300 ease-out bg-opacity-0 rounded-md cursor-pointer overflow-x-clip focus:outline-none focus:text-dynamic-neutral-900 dark:focus:text-dynamic-neutral-200 bg-neutral-500 hover:bg-opacity-50"
    >
      <div class="flex flex-row items-center p-4 space-x-3">
        <i
          :class="
            route.name === props.item.route.name
              ? props.item.activeIcon
              : props.item.icon
          "
          class="text-xl text-right fa-fw"
        ></i>
        <div
          class="flex truncate transition-all duration-300 ease-out overflow-x-clip 2xl:ml-0 2xl:opacity-100"
          :class="{
            'ml-2 opacity-0': !props.expanded,
            'ml-0 opacity-100': props.expanded,
          }"
        >
          <span>{{ props.item.label }}</span>
        </div>
      </div>
      <NotificationCounter
        :class="{
          'relative right-auto top-auto': props.expanded,
          'absolute right-0.5 top-2 2xl:relative 2xl:right-auto 2xl:top-auto':
            !props.expanded,
        }"
        :type="getTypeOfNotificationCountForRouteName(props.item.route.name)"
      />
    </RouterLink>
  </template>

  <template v-else>
    <a
      @click.stop="handleTap"
      :title="props.item.label + ' side menu item'"
      class="flex items-center p-4 space-x-3 text-base font-normal leading-6 transition duration-300 ease-out bg-opacity-0 rounded-md cursor-pointer overflow-x-clip focus:outline-none focus:text-dynamic-neutral-900 dark:focus:text-dynamic-neutral-200 bg-neutral-500 hover:bg-opacity-50"
    >
      <i
        :class="route.fullPath === props.item.url ? item.activeIcon : item.icon"
        class="ml-1 text-xl text-right fa-fw"
      ></i>
      <div
        class="flex truncate transition-all duration-300 ease-out overflow-x-clip 2xl:ml-0 2xl:opacity-100"
        :class="{ 'ml-2 opacity-0': !expanded, 'ml-0 opacity-100': expanded }"
      >
        <span>{{ item.label }}</span>
      </div>
    </a>
  </template>
</template>
@/stores/common/ui
