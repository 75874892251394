export const LOGIN_ROUTE = "login";
export const EXPLORE_ROUTE = "explore";
export const HOME_ROUTE = "home";
export const MESSAGES_ROUTE = "messages";
export const MESSAGE_THREAD_ROUTE = "direct-message-thread";
export const MESSAGE_GROUP_THREAD_ROUTE = "group-message-thread";
export const NETWORK_INDEX_ROUTE = "network-index";
export const NETWORK_ROUTE = "network";
export const NETWORK_FORM_ROUTE = "network-form";
export const NETWORK_FEED_ROUTE = "network-feed";
export const POST_DETAIL_ROUTE = "post-detail";
export const PROFILE_ROUTE = "profile";
export const PROFILE_STORIES_ROUTE = "profile-stories";
export const PROFILE_STORIES_DETAIL_ROUTE = "profile-stories-detail";
export const PROFILE_FEED_ROUTE = "profile-feed";
export const PROFILE_ACTIVITIES_ROUTE = "profile-activities";
export const SETTINGS_ROUTE = "settings";
export const TRENDS_ROUTE = "trends";
export const NOTIFICATIONS_ROUTE = "notifications";
export const BOOKMARKS_ROUTE = "bookmark";
export const PEOPLE_ROUTE = "people";
export const PEOPLE_FOLLOWING_ROUTE = "people-following";
export const PEOPLE_FOLLOWERS_ROUTE = "people-followers";
export const PEOPLE_CONTACTS_ROUTE = "people-contacts";
export const TAG_ROUTE = "tag";
export const ONBOARDING_ROUTE = "onboarding";
export const NOT_FOUND_ROUTE = "not-found";
export const POST_COMPOSE_ROUTE = "post-compose";
export const POST_REPLY_COMPOSE_ROUTE = "post-reply-compose";
export const PROFILE_POST_COMPOSE_ROUTE = "profile-post-compose";
export const NETWORK_POST_COMPOSE_ROUTE = "network-post-compose";


const ROUTES = {
  LOGIN: LOGIN_ROUTE,
  EXPLORE: EXPLORE_ROUTE,
  HOME: HOME_ROUTE,
  MESSAGES: MESSAGES_ROUTE,
  MESSAGE_THREAD: MESSAGE_THREAD_ROUTE,
  MESSAGE_GROUP_THREAD: MESSAGE_GROUP_THREAD_ROUTE,
  NETWORK_INDEX: NETWORK_INDEX_ROUTE,
  NETWORK: NETWORK_ROUTE,
  NETWORK_FORM: NETWORK_FORM_ROUTE,
  NETWORK_FEED: NETWORK_FEED_ROUTE,
  POST_DETAIL: POST_DETAIL_ROUTE,
  PROFILE: PROFILE_ROUTE,
  PROFILE_STORIES: PROFILE_STORIES_ROUTE,
  PROFILE_STORIES_DETAIL: PROFILE_STORIES_DETAIL_ROUTE,
  PROFILE_FEED: PROFILE_FEED_ROUTE,
  PROFILE_ACTIVITIES: PROFILE_ACTIVITIES_ROUTE,
  SETTINGS: SETTINGS_ROUTE,
  TRENDS: TRENDS_ROUTE,
  BOOKMARKS: BOOKMARKS_ROUTE,
  PEOPLE: PEOPLE_ROUTE,
  PEOPLE_FOLLOWING: PEOPLE_FOLLOWING_ROUTE,
  PEOPLE_FOLLOWERS: PEOPLE_FOLLOWERS_ROUTE,
  PEOPLE_CONCTACTS: PEOPLE_CONTACTS_ROUTE,

  NOT_FOUND: NOT_FOUND_ROUTE,
  NOTIFICATIONS: NOTIFICATIONS_ROUTE,
  TAG: TAG_ROUTE,
  ONBOARDING: ONBOARDING_ROUTE,
  POST_COMPOSE: POST_COMPOSE_ROUTE,
  POST_REPLY_COMPOSE: POST_REPLY_COMPOSE_ROUTE,
  PROFILE_POST_COMPOSE: PROFILE_POST_COMPOSE_ROUTE,
  NETWORK_POST_COMPOSE: NETWORK_POST_COMPOSE_ROUTE,
};

export default ROUTES;
