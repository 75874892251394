export default function groupMessagesByProximity(messages: Array<Message>) {
  const messagesByGroup = messages.reduce(function (
    groups: Array<Array<Message>>,
    message: Message
  ) {
    if (groups.length === 0) groups.unshift([message]);
    else if (
      groups[0][0].from_user_id === message.from_user_id
      //   !store.state.chats.chatTimeLabelsByChatId[message.chat_id][message.id]
    )
      groups[0].push(message);
    else groups.unshift([message]);
    return groups;
  },
  []);

  return messagesByGroup.reverse();
}
