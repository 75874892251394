<script setup lang="ts">
import { computed } from "vue";

import MessageListItemsGrouped from "@/components/MessageListItemsGrouped.vue";

import groupMessagesByProximity from "@/utils/groupMessagesByProximity";

const props = withDefaults(defineProps<PropTypes>(), {
  messages: () => [],
});

const messagesGrouped = computed(() =>
  groupMessagesByProximity(props.messages)
);

type PropTypes = {
  messages: Array<Message>;
  currentUser: User;
  showIcon: boolean;
};
</script>

<template>
  <!-- <template v-for="(message, index) in messages" :key="index">
    <MessageListItem
      :message="message"
      :showIcon="showIcon"
      :is-current-user="message.from_user_id === currentUser?.id"
    />
  </template> -->
  <template v-for="(group, index) in messagesGrouped" :key="index">
    <MessageListItemsGrouped
      :message-group="group"
      :showIcon="showIcon"
      :is-current-user="
        group.length > 0 && group[0].from_user_id === currentUser?.id
      "
    />
  </template>
</template>
