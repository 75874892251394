<script setup lang="ts">
import { Button, ProfilePhoto } from "@allaxis/ui";
import { ref } from "vue";

import useOverlayManager from "@/stores/common/overlay-manager";

import useAsset from "@/hooks/useAsset";

import DraftTextBox from "./DraftTextBox.vue";

const props = withDefaults(defineProps<PropTypes>(), {
  useLargeTextarea: false,
});

const emit = defineEmits<EventTypes>();

const overlayManager = useOverlayManager();
const asset = useAsset();

const postList = ref<Array<PostType>>([]);
const currentPostText = ref("");
const photos = ref<Array<File>>([]);
const photoUrls = ref<Array<string>>([]);

function handleTextUpdate(text: string) {
  currentPostText.value = text;
}

function handleFileInput(event: Event) {
  const element = event.target as HTMLInputElement;
  const files = element.files as FileList;

  const localPhotoUrls: Array<string> = [];
  const localPhotos: Array<File> = [];

  Array.from(files).forEach((file) => {
    localPhotoUrls.push(URL.createObjectURL(file));
    localPhotos.push(file);
  });

  photoUrls.value.push(...localPhotoUrls);
  photos.value.push(...localPhotos);
}

function addpostItem() {
  const localPost = {
    text: currentPostText.value,
    photos: photos.value,
    photoUrls: photoUrls.value,
  };
  postList.value.push(localPost);
  currentPostText.value = "";
  photos.value = [];
  photoUrls.value = [];
}

function emitPusblishEvent() {
  const localPost: PostType = { text: "", photos: [], photoUrls: [] };
  const localPostList = [...postList.value];

  if (currentPostText.value) localPost["text"] = currentPostText.value;
  if (photos.value.length > 0) localPost["photos"] = photos.value;

  if (currentPostText.value || photos.value.length > 0)
    localPostList.push(localPost);

  if (localPostList.length === 0) return;

  emit("publish", localPostList, function () {
    postList.value = [];
    currentPostText.value = "";
    photoUrls.value = [];
    photos.value = [];
  });
}

type PropTypes = {
  me: User;
  useLargeTextarea?: boolean;
};

type EventTypes = {
  (e: "publish", postList: Array<PostType>, onComplete: () => void): void;
};

type PostType = {
  text: string;
  photos: Array<File>;
  photoUrls: Array<string>;
};
</script>

<template>
  <div class="flex flex-col pt-4 text-neutral-700 dark:text-neutral-200">
    <div
      class="flex flex-row items-stretch space-x-4 whitespace-pre-wrap"
      v-for="(postItem, index) in postList"
      :key="index"
    >
      <div class="relative flex flex-col items-center flex-shrink-0 w-10">
        <span
          class="absolute w-3 h-3 mx-auto rounded-full top-2 bg-dynamic-neutral-200"
        ></span>
        <span
          :class="{ 'top-2': index === 0 }"
          class="w-[2px] bg-dynamic-neutral-200 absolute top-0 bottom-0"
        ></span>
      </div>
      <div class="flex-grow pb-3 space-y-2">
        <p>
          {{ postItem.text }}
          <span class="text-dynamic-neutral-500"
            >({{ index + 1 }}/{{ postList.length }})</span
          >
        </p>

        <div class="flex space-x-2" v-if="postItem.photoUrls">
          <div v-for="(photoUrl, index) in postItem.photoUrls" :key="index">
            <img
              :src="photoUrl"
              :alt="`photo staged for upload ${index + 1}`"
              class="w-20 h-20 rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row w-full space-x-2">
      <ProfilePhoto
        :placeholder-text="props.me.acronym"
        :image-url="
          asset(
            'profile/small/' + props.me.profile_photo?.filename,
            !!props.me.profile_photo
          )
        "
      />
      <div class="flex flex-col flex-grow space-y-2">
        <p class="block text-sm font-semibold leading-none">
          <span class="text-sm">{{ props.me.name }}</span>
          <span class="text-dynamic-neutral-500 dark:text-dynamic-neutral-400"
            >@{{ props.me.username }}</span
          >
        </p>
        <DraftTextBox
          full-width
          class="!mb-2"
          :model-value="currentPostText"
          @update:model-value="handleTextUpdate"
          :use-large-textarea="useLargeTextarea"
          :autoFocus="overlayManager.isPostComposeModalOpen"
        />
        <div
          class="relative inline-block scroll-pl-16 pl-16 space-x-2 overflow-x-scroll h-32 whitespace-nowrap snap-x -left-16 w-[calc(100%+4.8rem)] align-middle hide-scrollbar"
          v-if="photoUrls.length"
        >
          <div
            v-for="(url, key) in photoUrls || []"
            :key="key"
            class="box-border relative inline-block w-auto h-full overflow-hidden align-middle rounded-lg bg-dynamic-neutral-500/20 snap-start"
          >
            <img
              class="inline-flex object-cover object-center w-auto h-full max-h-full"
              :src="url"
              alt=""
            />
          </div>
        </div>
        <div
          class="flex flex-row items-center justify-between w-full align-middle"
        >
          <div class="flex flex-row justify-center">
            <Button
              class="hidden sm:flex"
              icon="far fa-plus mr-2"
              size="sm"
              :text="
                postList.length > 0 ? 'Add to thread...' : 'Start thread...'
              "
              :disabled="postList.length < 1 && currentPostText.length < 1"
              @click="addpostItem"
            />

            <Button
              class="flex sm:hidden"
              icon="far fa-plus"
              size="sm"
              :disabled="postList.length < 1 && currentPostText.length < 1"
              @click="addpostItem"
            />
          </div>
          <div class="flex space-x-2 sm:hidden">
            <Button
              size="sm"
              icon="far fa-camera"
              themed
              as="label"
              for="compose-photo-input"
            />
            <Button
              size="sm"
              type="primary"
              icon="far fa-paper-plane-top"
              themed
              @click="emitPusblishEvent"
            />
          </div>

          <div class="hidden space-x-2 sm:flex">
            <Button
              size="sm"
              icon="far fa-camera mr-2"
              text="Add photo"
              as="label"
              for="compose-photo-input"
              themed
            />
            <Button
              size="sm"
              type="primary"
              text="Publish"
              themed
              @click="emitPusblishEvent"
            />

            <input
              id="compose-photo-input"
              class="hidden"
              accept="image/gif, image/jpeg, image/png"
              type="file"
              multiple
              @change="handleFileInput"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
