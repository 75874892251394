import ROUTES, { NETWORK_ROUTE, NETWORK_FORM_ROUTE } from "@/router/constants";

const NetworkIndex = () => import("@/apps/NetworkApp.vue");
const NetworkApp = () => import("@/apps/NetworkDetailApp.vue");
const NetworkPostFeed = () => import("@/partials/NetworkPostFeed.vue");
const NetworkForm = () => import("@/partials/NetworkForm.vue");


export default [
  {
    name: NETWORK_ROUTE,
    path: "/axis/:slug",
    alias: "/ap/:slug",
    meta: { secure: true },
    component: NetworkApp,
    children: [
      {
        name: ROUTES.NETWORK_FEED,
        path: "",
        component: NetworkPostFeed,
      },
    ],
    redirect: () => ({
      name: ROUTES.NETWORK_FEED,
    }),
  },
  {
    name: NETWORK_FORM_ROUTE,
    path: "/axis/create",
    component: NetworkForm,
  },
  {
    name: ROUTES.NETWORK_INDEX,
    path: '/axis',
    component: NetworkIndex,
  }
];
