<script setup lang="ts">
import Document from "@tiptap/extension-document";
import History from "@tiptap/extension-history";
import Mention from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import {
  Editor,
  EditorContent,
  VueRenderer,
  type JSONContent,
} from "@tiptap/vue-3";
import tippy, { type GetReferenceClientRect } from "tippy.js";
import { onMounted, onBeforeUnmount, ref, watch } from "vue";

import MentionPopupList from "@/components/MentionPopupList.vue";

import api from "@/services/api";

const props = withDefaults(defineProps<PropTypes>(), {
  modelValue: "",
  useLargeTextarea: false,
  fullWidth: false,
  placeholder: "Write something...",
  autoFocus: false,
});

const emit = defineEmits<EmitTypes>();
const editor = ref<Editor | null>(null);
const mentions = ref<Array<User>>([]);
const loadingMentions = ref(false);

onMounted(() => {
  console.log({
    autofocus: props.autoFocus,
  });
  (editor.value as Editor) = new Editor({
    content: props.modelValue,
    extensions: [
      Document,
      Paragraph,
      Text,
      History,
      Placeholder.configure({
        placeholder: props.placeholder,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion: {
          items: ({ query }) => {
            loadingMentions.value = true;
            api
              .get(`/user-typeahead?query=${query}`)
              .then(({ data, status }) => {
                if (status === 200) {
                  loadingMentions.value = true;
                  mentions.value = data.users;
                }
              });

            return mentions.value
              .filter((item) =>
                item.username.toLowerCase().startsWith(query.toLowerCase())
              )
              .slice(0, 5);
          },
          render: () => {
            let component: any;
            let popup: any;

            return {
              onStart: (suggestionProps) => {
                component = new VueRenderer(MentionPopupList, {
                  editor: editor.value as Editor,
                  props: suggestionProps,
                });

                popup = tippy("body", {
                  getReferenceClientRect:
                    suggestionProps?.clientRect as GetReferenceClientRect,
                  appendTo: () => document.body,
                  content: component.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: "manual",
                  placement: "bottom-start",
                });
              },
              onUpdate(suggestionProps) {
                component.updateProps(suggestionProps);

                popup[0].setProps({
                  getReferenceClientRect: suggestionProps.clientRect,
                });
              },
              onKeyDown(suggestionProps) {
                if (suggestionProps.event.key === "Escape") {
                  popup[0].hide();
                  return true;
                }

                return component.ref?.onKeyDown(suggestionProps);
              },
              onExit() {
                popup[0].destroy();
                component.destroy();
              },
            };
          },
        },
      }),
    ],
    onUpdate: () => {
      emit(
        "update:modelValue",
        (editor.value as Editor)
          .getJSON()
          .content?.map((c: JSONContent) => {
            if (!c.content) return "";
            return c.content
              .map((cp: any) => {
                if (cp.type === "mention") {
                  return "@" + cp.attrs.id;
                }
                if (cp.type !== "text") return "";
                return cp.text;
              })
              .join("");
          })
          .join("\n") as string
      );
    },
    parseOptions: {
      preserveWhitespace: "full",
    },
  });
  if (props.autoFocus) {
    (editor.value as Editor).commands.focus();
  }
});

onBeforeUnmount(() => {
  setTimeout(() => (editor.value as Editor)?.destroy(), 1000);
});

watch(
  () => props.modelValue,
  (value) => {
    const jsonStr = editor.value
      ?.getJSON()
      .content?.map((c: JSONContent) => {
        if (!c.content) return "";
        return c.content
          .map((cp: any) => {
            if (cp.type === "mention") {
              return "@" + cp.attrs.id;
            }
            if (cp.type !== "text") return "";
            return cp.text;
          })
          .join("");
      })
      .join("\n") as string;
    // HTML
    const isSame = jsonStr === value;
    // JSON
    // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)
    if (isSame) {
      return;
    }
    editor.value?.commands.setContent(value, false, {
      preserveWhitespace: "full",
    });
  }
);

watch(
  () => props.autoFocus,
  (next) => {
    if (next && editor.value) {
      editor.value.commands.focus();
    }
  }
);

type PropTypes = {
  modelValue: string;
  useLargeTextarea?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
};

type EmitTypes = {
  (e: "update:modelValue", text: string): void;
};
</script>

<template>
  <div class="flex flex-grow">
    <editor-content
      class="draft-text-box"
      :class="{ large: props.useLargeTextarea }"
      :editor="(editor as Editor)"
    />
  </div>
</template>

<style lang="postcss">
.draft-text-box {
  @apply w-full;
  .ProseMirror {
    @apply break-all max-w-full focus:outline-none whitespace-pre-wrap;
  }

  .ProseMirror.ProseMirror-focused {
    @apply text-dynamic-neutral-800 dark:text-dynamic-neutral-100;
  }

  &.large .ProseMirror {
    @apply min-h-[25vh] sm:min-h-[15vh];
  }

  .ProseMirror p:not(.is-editor-empty) {
    @apply text-dynamic-neutral-600 dark:text-dynamic-neutral-300 text-sm;
  }

  .ProseMirror p.is-editor-empty:first-child::before {
    @apply dark:text-dynamic-neutral-500 text-dynamic-neutral-400 text-sm;

    content: attr(data-placeholder);
    float: left;
    pointer-events: none;
    height: 0;
  }

  .mention {
    @apply text-theme-accent-500 font-semibold;

    background-color: rgba(#a975ff, 0.1);
    border-radius: 0.3rem;
    padding: 0.1rem 0.3rem;
  }
}
</style>
