import axios from "axios";

// const BASE_URL = "http://" + window.location.hostname + ":8000";
const BASE_URL = import.meta.env.VITE_BASE_URL;

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.baseURL = BASE_URL;

const api = axios.create({
  baseURL: BASE_URL + import.meta.env.VITE_API_PREFIX,
});

export default api;
