<script setup lang="ts">
import { ProfilePhoto } from "@allaxis/ui";

import LinkCardPreview from "@/components/LinkCardPreview.vue";
import UserTextTransform from "@/components/UserTextTransform.vue";

import useAsset from "@/hooks/useAsset";

import MessagePhotoStack from "./MessagePhotoStack.vue";

const props = defineProps<PropTypes>();

const asset = useAsset();

type PropTypes = {
  message: Message;
  isCurrentUser: boolean;
  showIcon: boolean;
  showSenderName: boolean;
  showTimestamp: boolean;
  showMessageTail: boolean;
};
</script>

<template>
  <div
    class="w-full"
    :class="{
      'flex flex-row-reverse': !props.isCurrentUser,
      'flex flex-row': props.isCurrentUser,
      'px-10': props.showIcon,
      'px-2': !props.showIcon,
    }"
    v-if="props.message.links?.length || props.message.photos?.length"
  >
    <LinkCardPreview
      class="w-full max-w-sm"
      v-if="message.links?.length"
      :link="message.links[0]"
    />
    <MessagePhotoStack
      class="p-1 rounded-lg"
      :class="{
        'flex flex-row bg-dynamic-neutral-200 bg-tint-opacity-100 dark:bg-dynamic-neutral-800 dark:text-white text-dynamic-neutral-800 dark:text-dynamic-neutral-50':
          !!isCurrentUser,
        'flex flex-row-reverse bg-dynamic-neutral-200 theme-tint dark:bg-dynamic-neutral-800 tint-rate-20 text-right text-theme-aware-neutral-200 right-message-bubble dark:text-theme-aware-neutral-800 bg-tint-opacity-100':
          !isCurrentUser,
      }"
      :photos="message.photos ?? []"
      v-if="message.photos?.length"
    />
  </div>
  <div
    :class="{
      'flex flex-row-reverse': !props.isCurrentUser,
      'flex flex-row': props.isCurrentUser,
    }"
    class="relative items-end"
  >
    <ProfilePhoto
      v-if="props.showIcon"
      size="sm"
      :placeholder-text="(props.message.from_user as User).acronym"
      :image-url="
        asset('profile/small/' + (props.message.from_user as User).profile_photo?.filename, !!(props.message.from_user as User).profile_photo)
      "
    />
    <div
      :class="{
        'bg-dynamic-neutral-200 bg-tint-opacity-100 dark:bg-dynamic-neutral-800 dark:text-white text-dynamic-neutral-800 dark:text-dynamic-neutral-50':
          !!props.isCurrentUser,
        'bg-dynamic-neutral-200 theme-tint dark:bg-dynamic-neutral-800 tint-rate-20 text-right text-theme-aware-neutral-200 right-message-bubble dark:text-theme-aware-neutral-800 bg-tint-opacity-100':
          !props.isCurrentUser,
      }"
      class="relative flex flex-col px-3 py-1 mx-2 leading-8 rounded-lg"
    >
      <!-- <h4
        v-if="props.showSenderName"
        class="font-semibold leading-none text-md"
      >
        {{ message.from_user?.name }}
      </h4> -->
      <p class="z-[2]">
        <UserTextTransform :text="props.message.text" />
      </p>
      <p
        v-if="props.showTimestamp"
        class="text-xs text-dynamic-neutral-700 dark:text-dynamic-neutral-300 z-[2]"
      >
        {{ (message as any).created_at_readable }}
      </p>
      <div
        v-if="props.showMessageTail"
        :class="{
          '-left-1.5 rotate-[227deg]': props.isCurrentUser,
          '-right-1.5 -rotate-[227deg]': !props.isCurrentUser,
        }"
        class="absolute bottom-0 w-3 overflow-hidden"
      >
        <!-- Left aligned bubble -->
        <div
          v-if="props.isCurrentUser"
          class="h-4 origin-bottom-right transform rotate-45 bg-dynamic-neutral-200 bg-tint-opacity-100 dark:bg-dynamic-neutral-800"
        ></div>
        <!-- Right aligned bubble -->
        <div
          v-if="!props.isCurrentUser"
          class="h-4 text-right origin-top-left transform rotate-45 bg-dynamic-neutral-200 theme-tint dark:bg-dynamic-neutral-800 tint-rate-20 text-theme-aware-neutral-200 right-message-bubble dark:text-theme-aware-neutral-800 bg-tint-opacity-100"
        ></div>
      </div>
    </div>
    <span v-if="props.showIcon" class="flex-shrink-0 w-8 md:flex"></span>
  </div>
</template>

<style lang="postcss">
.message-bubble::after {
  content: "";
  position: absolute;
  bottom: 15px;
  left: -25px;
  width: 70px;
  height: 30px;
  background-color: inherit;
  transform: rotate(325deg) skew(55deg);
  transform-origin: top right;
  border-radius: 15% 0 0 0 / 25%;
  z-index: 0;
}

.message-bubble.right-message-bubble::after {
  @apply bg-tint-opacity-100;
  left: unset;
  right: 30px;
  transform: rotate(360deg) skew(55deg);
  border-radius: 0 0 15% 0 / 25%;
  bottom: 5px;
}
</style>
