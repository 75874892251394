<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from "vue";

const props = withDefaults(defineProps<PropsType>(), {
  placeholderText: "Keep pulling to reload",
  text: "Reloading...",
});

const emit = defineEmits<EventsType>();

const pullToRefreshVisible = ref(false);
const isLoading = ref(false);
const touchstartY = ref(0);

const minRunningDistance = 200;

function pullToRefreshStart(e: TouchEvent) {
  touchstartY.value = e.touches[0].clientY;
  console.log({
    ptfStart: {
      touchstartY: touchstartY.value,
    },
  });
}

function pullToRefreshMove(e: TouchEvent) {
  const touchY = e.touches[0].clientY;
  const touchDiff = touchY - touchstartY.value;
  if (touchDiff > minRunningDistance && window.scrollY === 0) {
    pullToRefreshVisible.value = true;
  }

  if (touchDiff > minRunningDistance + 300 && window.scrollY === 0) {
    isLoading.value = true;
  }
}

function pullToRefreshEnd() {
  console.log("Pull refresh end: ", {
    pullToRefreshVisible: pullToRefreshVisible.value,
  });
  if (pullToRefreshVisible.value) {
    pullToRefreshVisible.value = false;
    emit("pull", onComplete);
  }
}

function onComplete() {
  isLoading.value = false;
}

onMounted(function () {
  document.addEventListener("touchstart", pullToRefreshStart);
  document.addEventListener("touchmove", pullToRefreshMove);
  document.addEventListener("touchend", pullToRefreshEnd);
});

onUnmounted(function () {
  document.removeEventListener("touchstart", pullToRefreshStart);
  document.removeEventListener("touchmove", pullToRefreshMove);
  document.removeEventListener("touchend", pullToRefreshEnd);
});

type PropsType = {
  placeholderText?: string;
  text?: string;
};

type EventsType = {
  (e: "pull", onComplete?: () => void): () => void;
};
</script>

<template>
  <Transition
    enter-active-class="duration-300 ease-out"
    enter-from-class="-translate-y-5"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="duration-300 ease-out"
    leave-from-class="translate-y-0 opacity-100"
    leave-to-class="-translate-y-5"
  >
    <div
      v-show="pullToRefreshVisible || isLoading"
      class="fixed top-0 z-50 flex flex-row items-center justify-center w-full p-2 pull-to-refresh text-dynamic-neutral-700 dark:text-dynamic-neutral-200 pt-20-safe"
    >
      <div
        class="px-6 py-1 rounded-full shadow-xl shadow-black/50 dark:shadow-black bg-dynamic-neutral-200 dark:bg-dynamic-neutral-800"
      >
        <span v-if="!isLoading">{{ props.placeholderText }}</span>
        <span v-else>{{ props.text }}</span>
      </div>
    </div>
  </Transition>
</template>
