<script setup lang="ts">
import { useRoute } from "vue-router";

import AppNotification from "@/components/AppNotification.vue";

import useUiNotifications from "@/hooks/useUiNotifications";

import { ROUTES } from "@/router";

const { notifications, dismiss } = useUiNotifications();

const route = useRoute();
</script>

<template>
  <div
    class="relative flex flex-col items-end justify-end app-notification-container z-[60]"
  >
    <div
      v-if="
        ![
          ROUTES.MESSAGE_GROUP_THREAD,
          ROUTES.MESSAGES,
          ROUTES.MESSAGE_THREAD,
        ].includes(route.name as unknown as string)
      "
      class="absolute bottom-0 w-full md:max-w-screen-xs p-1.5 space-y-1.5 md:p-4 md:space-y-4"
    >
      <transition-group
        move-class="transition duration-300 ease-out"
        enter-active-class="transition duration-300 ease-out transform"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="absolute transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <AppNotification
          :notification="notification"
          v-for="(notification, i) in notifications"
          :key="i"
          @close="() => dismiss(notification)"
        />
      </transition-group>
    </div>
  </div>
</template>
