<script setup lang="ts">
import { Button } from "@allaxis/ui";

import BaseModal from "@/components/BaseModal.vue";

import type { BeforeInstallPromptEvent } from "@/types/appTypes";

const props = defineProps<PropsType>();
const emit = defineEmits<EventsType>();

function installPwa() {
  console.log("Installing PWA.");
  props.installEvent?.prompt();
}

function close() {
  emit("close");
}

type PropsType = {
  open?: boolean;
  installEvent?: BeforeInstallPromptEvent;
};

type EventsType = {
  (e: "close"): void;
};
</script>

<template>
  <transition
    enter-from-class="transform translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
    enter-active-class="transition duration-300 ease-out"
    enter-to-class="transform translate-y-0 opacity-100 sm:scale-100"
    leave-from-class="transform translate-y-0 opacity-100 sm:scale-100"
    leave-active-class="transition duration-200 ease-in"
    leave-to-class="transform translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
  >
    <BaseModal
      class="fixed z-50 transition-all transform-gpu"
      modal-class="max-w-screen-xs"
      mobile-position="center"
      @close="close"
    >
      <div class="max-w-screen-sm p-4 space-y-10">
        <div
          class="flex items-center justify-center w-12 h-12 mx-auto bg-[#d8cdf8] rounded-lg p-2 shadow-[#7d67be] shadow-sm"
        >
          <img src="../assets/logo.svg" />
        </div>
        <div class="space-y-2 text-center">
          <h3
            class="text-lg font-medium leading-6 text-dynamic-neutral-800 dark:text-dynamic-neutral-200"
          >
            Install App
          </h3>
          <p
            class="text-sm leading-8 text-dynamic-neutral-700 dark:text-dynamic-neutral-300"
          >
            To get notifications, homescreen icon, app menus and notification
            badges, please install as a PWA.
          </p>
        </div>

        <div class="flex flex-col space-y-2 text-center">
          <Button
            @click="installPwa"
            text="Install as PWA"
            centered
            type="primary"
            themed
          />
          <Button
            @click="close"
            text="Continue using web"
            centered
            type="default"
          />
        </div>
      </div>
    </BaseModal>
  </transition>
</template>
