<script lang="ts" setup>
import { ProfilePhoto } from "@allaxis/ui";
import { ref, type PropType } from "vue";

import SideMenuItem from "@/components/SideMenuItem.vue";

import useAuthStore from "@/stores/common/auth";

import useAsset from "@/hooks/useAsset";

import { ROUTES } from "@/router";
import type { IMenuItem } from "@/types/appTypes";

defineProps({
  user: {
    // eslint-disable-next-line no-undef
    type: Object as PropType<User>,
    required: false,
    default: () => ({}),
  },
  expanded: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["collapse", "expand", "logout"]);

const asset = useAsset();

const auth = useAuthStore();

const appMenuItems = ref<Array<IMenuItem>>([
  {
    label: "Home",
    icon: "far fa-house",
    activeIcon: "fas fa-house",
    route: {
      name: ROUTES.HOME,
    },
  },
  {
    label: "Explore",
    icon: "far fa-search",
    activeIcon: "fas fa-search",
    route: {
      name: ROUTES.EXPLORE,
    },
  },
  /*
  {
    label: "Trends",
    icon: "far fa-hashtag",
    activeIcon: "fas fa-hashtag",
    route: {
      name: ROUTES.TRENDS
    },
  },
  /*/

  {
    label: "Notifications",
    icon: "far fa-users",
    activeIcon: "fas fa-users",
    route: {
      name: ROUTES.NETWORK_INDEX,
    },
  },

  //*/

  {
    label: "Messages",
    icon: "far fa-envelope",
    activeIcon: "fas fa-envelope",
    route: {
      name: ROUTES.MESSAGES,
    },
  },
]);

const userMenuItems = ref<Array<IMenuItem>>([
  {
    label: "People",
    icon: "far fa-user-group",
    activeIcon: "fas fa-user-group",
    route: {
      name: ROUTES.PEOPLE,
    },
  },
  {
    label: "Bookmarks",
    icon: "far fa-bookmark w-5",
    activeIcon: "fas fa-bookmark w-5",
    route: {
      name: ROUTES.BOOKMARKS,
    },
  },
  {
    label: "Notifications",
    icon: "far fa-bell w-4",
    activeIcon: "fas fa-bell w-4",
  },
]);
</script>

<template>
  <aside
    id="sidebar"
    :class="{ 'w-full': expanded }"
    class="fixed top-0 left-0 z-50 flex h-full bg-dynamic-neutral-900/10 glass-blur xl:w-auto"
    @click.stop="emit('collapse')"
  >
    <section
      id="side-menu"
      @click.stop
      :class="{
        collapsed: !expanded,
        expanded: expanded,
      }"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex flex-col flex-1 bg-dynamic-neutral-light dark:bg-dynamic-neutral-900"
      >
        <RouterLink
          :to="{ name: ROUTES.HOME }"
          class="flex flex-row items-center justify-start flex-shrink-0 px-6 space-x-2 overflow-x-hidden pl-6-safe pt-4-safe h-14"
        >
          <img
            class="w-8 h-8 drop-shadow-md"
            src="../assets/logo.svg"
            alt=""
            loading="lazy"
          />
          <!-- <i class="text-3xl text-indigo-500 fas fa-cube"></i> -->
          <h4
            :class="{
              'opacity-0 ml-4 2xl:opacity-100': !expanded,
              'opacity-100 ml-0': expanded,
            }"
            class="text-2xl font-semibold transition-all text-dynamic-neutral-600 dark:text-dynamic-neutral-300"
          >
            Allaxis
          </h4>
        </RouterLink>

        <nav
          class="flex flex-col flex-1 flex-grow mt-4 overflow-y-scroll hide-scrollbar md:space-y-5 max-h-[calc(100%-9.5rem)]"
          aria-label="Sidebar"
        >
          <div
            class="flex-col hidden px-2 space-y-2 pl-2-safe md:flex lg:flex aspect-landscape:md:hidden lg:!flex text-dynamic-neutral-600 dark:text-dynamic-neutral-300"
          >
            <template v-for="(item, index) in appMenuItems" :key="index">
              <SideMenuItem :item="item" :expanded="expanded" />
            </template>
          </div>

          <div
            class="flex flex-col px-2 space-y-2 pl-2-safe text-dynamic-neutral-600 dark:text-dynamic-neutral-300"
          >
            <RouterLink
              :to="{
                name: ROUTES.PROFILE,
                params: { username: user.username },
              }"
              @click="emit('collapse')"
              class="flex items-center p-4 text-base font-normal leading-6 transition duration-300 ease-out rounded-md cursor-pointer bg-dynamic-neutral-light dark:bg-dynamic-neutral-900 overflow-x-clip hover:bg-dynamic-neutral-100 dark:hover:bg-dynamic-neutral-800 group hover:text-dynamic-neutral-900 dark:hover:text-dynamic-neutral-200 focus:outline-none focus:text-dynamic-neutral-900 dark:focus:text-dynamic-neutral-200"
            >
              <ProfilePhoto
                :placeholder-text="user.acronym"
                :image-url="
                  asset(
                    'profile/small/' + user.profile_photo?.filename,
                    !!user.profile_photo
                  )
                "
                size="sm"
                class="mr-3"
              />
              <div
                class="flex flex-col transition-all duration-300 ease-out overflow-x-clip 2xl:ml-0 2xl:opacity-100"
                :class="{
                  'ml-2 opacity-0': !expanded,
                  'ml-0 opacity-100': expanded,
                }"
              >
                <p class="font-medium text-md line-clamp-1">
                  {{ user.name }}
                </p>
                <p class="text-xs font-medium line-clamp-1">
                  @{{ user.username }}
                </p>
              </div>
            </RouterLink>
          </div>

          <div
            class="pr-2 space-y-2 pl-2-safe pb-2-safe text-dynamic-neutral-600 dark:text-dynamic-neutral-300"
          >
            <template v-for="(item, index) in userMenuItems" :key="index">
              <SideMenuItem :item="item" :expanded="expanded" />
            </template>
          </div>
        </nav>

        <div id="settings-button">
          <SideMenuItem
            :expanded="expanded"
            :item="{
              label: 'Logout',
              icon: 'far fa-power-off',
              activeIcon: 'fas fa-power-off',
              tapHandler: () => {
                auth.sessionEnd();
              },
            }"
          />
        </div>
      </div>
    </section>
  </aside>
</template>
@/stores/common/auth
