// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Notifications from "@kyvg/vue3-notification";
import { initializeApp } from "firebase/app";
import { createPinia } from "pinia";
import { registerSW } from "virtual:pwa-register";
import { createApp, markRaw } from "vue";

import useAuthStore from "@/stores/common/auth";

import App from "./App.vue";
import router from "./router";

import "./app.css";

const app = createApp(App);
const pinia = createPinia();

pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

app.use(pinia);
app.use(Notifications);

const auth = useAuthStore();

auth.loadSessionState().then(() => {
  app.use(router);
  if (import.meta.env.PROD) {
    const firebaseConfig = {
      apiKey: import.meta.env.VITE_FIREBASE_APP_KEY,
      authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
      appId: import.meta.env.VITE_FIREBASE_APP_ID,
      measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
    };

    initializeApp(firebaseConfig);
  }
  app.mount("#app");
});

// const updateSW = registerSW({
//   onNeedRefresh() {
//     console.log("Need to refresh");
//     updateSW();
//   },
//   onOfflineReady() {
//     console.log("Offline ready");
//   },
// });
