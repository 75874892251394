import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, inject, ref } from "vue";

import useAuthStore from "@/stores/common/auth";
import usePostStore from "@/stores/resources/posts-store";
import useUsersStore from "@/stores/resources/users-store";

import useAppConfig from "@/hooks/useAppConfig";
import useNativeShare from "@/hooks/useNativeShare";
import useUiNotifications from "@/hooks/useUiNotifications";

import type { BeforeInstallPromptEvent } from "@/types/appTypes";

function getCoords(elem: Element) {
  // crossbrowser version
  const box = elem.getBoundingClientRect();

  const body = document.body;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  // const clientTop = docEl.clientTop || body.clientTop || 0;
  // const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop;
  const left = box.left + scrollLeft;

  return {
    top: Math.round(top) + box.height + 2,
    left: Math.round(left) - (265 - (box.width + 5)),
    width: box.width,
    height: box.height,
  };
}

const useOverlayManager = defineStore("overlay-manager-store", function () {
  const { sharePost } = useNativeShare();

  const contextMenuConfig = ref<ContextMenuConfig | null>(null);
  const pwaInstallEvent = ref<BeforeInstallPromptEvent | Event>();
  const postDeleteCallback = ref<() => void>();
  const usernameForQrCode = ref();

  const isUserBottomSheetOpen = ref(false);
  const isPostComposeModalOpen = ref(false);
  const isContextMenuOpen = computed(() => !!contextMenuConfig.value);
  const isPwaInstallModalOpen = computed(() => !!pwaInstallEvent.value);
  const isPostDeleteModalOpen = computed(
    () => typeof postDeleteCallback.value === "function"
  );
  const isUserQrCodeModalOpen = computed(() => !!usernameForQrCode.value);

  const isOverlayOpen = computed(
    () =>
      !!isUserBottomSheetOpen.value ||
      !!isPostComposeModalOpen.value ||
      !!isContextMenuOpen.value ||
      !!isPwaInstallModalOpen.value ||
      !!isPostDeleteModalOpen.value ||
      isUserQrCodeModalOpen.value
  );

  const authStore = useAuthStore();
  const postStore = usePostStore();
  const userStore = useUsersStore();

  const { notify } = useUiNotifications();

  function reset(callback?: <T>(...args: Array<T>) => void) {
    if (typeof callback === "function") callback();
    isUserBottomSheetOpen.value = false;
    isPostComposeModalOpen.value = false;
    contextMenuConfig.value = null;
    pwaInstallEvent.value = undefined;
    postDeleteCallback.value = undefined;
    usernameForQrCode.value = undefined;
  }

  // #region Post overlays.

  function showPostComposeModal() {
    reset();
    isPostComposeModalOpen.value = true;
  }

  function showContextMenu(config: ContextMenuConfig) {
    contextMenuConfig.value = config;
  }

  function showPostContextMenu(
    event: Event,
    postId: number,
    options?: {
      removeCallback?: () => void | Promise<void>;
    }
  ) {
    reset();
    if (!event.target) return;
    const currentUser = authStore.user;
    const post = postStore.postsById[postId];
    const isPostOwner = post.user_id === currentUser.id;
    const { top, left } = getCoords(event.target as Element);

    const config = {
      left,
      top,
      lists: [
        {
          items: [
            {
              label: "Pin",
              icon: "fas fa-thumbtack",
              action: async () => {
                try {
                  reset();
                  await postStore.pinPost(postId);
                  notify({
                    title: "Post pinned to profile!",
                  });
                } catch {
                  notify({
                    title: "Error pinning post to profile.",
                  });
                }
              },
              show: isPostOwner,
            },

            {
              label: "Link",
              icon: "fas fa-link",
              action: async () => {
                try {
                  if (navigator.clipboard) {
                    await navigator.clipboard.writeText(
                      `${import.meta.env.VITE_BASE_URL}/posts/${postId}`
                    );
                    reset();
                    notify({
                      title: "Link copied!",
                      type: "success",
                    });
                  } else {
                    console.log("Clipboard access is limited.");
                  }
                } catch (e) {
                  notify({
                    title: "Error copying link",
                    text: `${(e as any).message}`,
                    type: "error",
                  });
                }
              },
              show: !!navigator.clipboard,
            },

            {
              label: "Share via...",
              icon: "fas fa-share-alt",
              action: async () => {
                // const post = postStore.postsById[postId];
                // if (!post) return; // Error handling overhere.
                // const user = userStore.usersById[post.user_id];
                // if (!user) return; // Error handling overhere

                try {
                  // await window.navigator.share({
                  //   title: `Check out ${user.name}'s Allaxis post`,
                  //   text: post.text,
                  //   url: `${import.meta.env.VITE_BASE_URL}/post/${postId}`,
                  // });
                  sharePost(postId);
                  reset();
                } catch (err) {
                  notify({
                    title: "Something went wrong while attempting to share",
                    text: `${(err as any).message}`,
                    type: "error",
                  });
                  console.log(err);
                }
              },
              show: "share" in navigator,
            },

            {
              label: "Delete",
              icon: "fas fa-trash",
              action: () => {
                reset(); // Reset first, so that callback is not removed.
                if (options?.removeCallback) {
                  showPostDeleteAlertModal(
                    options.removeCallback as () => Promise<void>
                  );
                }
              },
              show: isPostOwner,
            },

            {
              label: "Report",
              icon: "fas fa-flag",
              action: () => console.log("Reporting post ", postId),
              show: false,
            },
          ],
        },

        {
          items: [
            {
              label: "Cancel",
              icon: "fas fa-times",
              show: true,
              action: () => reset(),
            },
          ],
        },
      ],
    };

    showContextMenu(config);
  }

  function showPostDeleteAlertModal(callback?: () => Promise<void>) {
    postDeleteCallback.value = async () => {
      typeof callback === "function" && (await callback());
      reset();
      notify({
        title: "Post has been deleted",
        type: "success",
      });
    };
  }

  function showRecentPeopleModal() {
    reset();
  }

  function showUserBottomSheet() {
    reset();
    isUserBottomSheetOpen.value = true;
  }

  function showPwaInstallModal(event: BeforeInstallPromptEvent | Event) {
    reset();
    pwaInstallEvent.value = event;
  }

  function showUserQrCodeModal(username: string) {
    reset();
    usernameForQrCode.value = username;
  }

  // #endregion

  return {
    reset,
    showPostComposeModal,
    showPostContextMenu,
    showRecentPeopleModal,
    showPostDeleteAlertModal,
    showUserBottomSheet,
    showPwaInstallModal,
    showUserQrCodeModal,

    contextMenuConfig,
    pwaInstallEvent,
    postDeleteCallback,
    usernameForQrCode,

    isOverlayOpen,
    isUserBottomSheetOpen,
    isPostComposeModalOpen,
    isContextMenuOpen,
    isPwaInstallModalOpen,
    isPostDeleteModalOpen,
    isUserQrCodeModalOpen,
  };
});

export default useOverlayManager;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOverlayManager, import.meta.hot));
}

export type ContextMenuItem = {
  label: string;
  icon: string;
  show?: boolean;
  action: () => void;
};

export type ContextMenuConfig = {
  lists: Array<{
    label?: string;
    description?: string;
    direction?: "vertical" | "horizontal";
    items: Array<ContextMenuItem>;
  }>;
  top?: number;
  left?: number;
};
