<script lang="ts" setup>
import { AuthenticationForm } from "@allaxis/ui";
import { ref } from "vue";

import useAuthStore from "@/stores/common/auth";

const authStore = useAuthStore();

const isLoggingIn = ref(false);

async function handleLoginFormSubmit(
  payload: {
    userInput: string;
    passwordInput: string;
    rememberMeInput: boolean;
  },
  onComplete?: () => void
) {
  isLoggingIn.value = true;
  await new Promise((resolve) => setTimeout(() => resolve(0), 2000));
  await authStore.sessionStart(
    payload.userInput,
    payload.passwordInput,
    payload.rememberMeInput
  );
  onComplete && onComplete();
  isLoggingIn.value = false;
}
</script>

<template>
  <div
    class="flex flex-col items-center w-full h-screen px-4 pt-10-safe bg-dynamic-neutral-100 dark:bg-dynamic-neutral-950"
  >
    <div class="flex items-center justify-center mb-10">
      <a class="w-20 h-20" href="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 360 360"
          version="1.1"
          xml:space="preserve"
          style="
            fill-rule: evenodd;
            clip-rule: evenodd;
            stroke-linejoin: round;
            stroke-miterlimit: 2;
          "
          class="w-full h-full fill-current text-dynamic-neutral-500 drop-shadow-lg"
        >
          <g id="layer1">
            <path
              id="path3346"
              d="M30.182,91.997l150,-86.603l-0.002,173.204l-149.998,-86.601Z"
              style="fill: #ffad00"
              shape-rendering="crispEdges"
            />
            <path
              id="path3348"
              d="M30.182,265.202l149.998,-86.604l-0.002,173.204l-149.996,-86.6Z"
              style="fill: #635bff"
              shape-rendering="crispEdges"
            />
            <path
              id="path3350"
              d="M180.18,178.598l149.998,-86.603l0.007,173.208l-150.005,-86.605Z"
              style="fill: #b5de01"
              shape-rendering="crispEdges"
            />
            <path
              id="path3352"
              d="M30.182,91.997l-0,173.204l149.998,-86.603l-149.998,-86.601Z"
              style="fill: #9b5bff"
              shape-rendering="crispEdges"
            />
            <path
              id="path3354"
              d="M180.179,5.398l-0.001,173.205l149.998,-86.604l-149.998,-86.596l0.001,-0.005Z"
              style="fill: #ffad00"
              shape-rendering="crispEdges"
            />
            <path
              id="path3356"
              d="M180.18,178.598l-0.002,173.204l150.007,-86.599l-150.005,-86.605Z"
              style="fill: #00c4df"
              shape-rendering="crispEdges"
            />
          </g>
        </svg>
      </a>
    </div>
    <AuthenticationForm
      :is-logging-in="isLoggingIn"
      @login="handleLoginFormSubmit"
    />
  </div>
</template>
