import { defineComponent as O, openBlock as s, createBlock as E, resolveDynamicComponent as N, normalizeClass as C, withCtx as M, createElementBlock as d, createCommentVNode as f, toDisplayString as w, createElementVNode as l, Fragment as I, renderList as _, ref as P, mergeProps as se, renderSlot as q, createVNode as H, normalizeStyle as Oe, withModifiers as A, unref as j, getCurrentScope as Se, onScopeDispose as je, watch as ie, computed as S, inject as L, Teleport as de, cloneVNode as Ee, h as Q, onMounted as V, watchEffect as Ae, provide as K, onUnmounted as ue, createTextVNode as Ie, withDirectives as Le, vModelCheckbox as He, createStaticVNode as Re } from "vue";
const _e = {
  key: 0,
  class: "inline-flex items-center justify-center"
}, Ue = {
  key: 1,
  class: "w-5 h-5 mr-3 -ml-1 text-dynamic-neutral-light animate-spin",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}, De = /* @__PURE__ */ l("circle", {
  class: "opacity-25",
  cx: "12",
  cy: "12",
  r: "10",
  stroke: "currentColor",
  "stroke-width": "4"
}, null, -1), qe = /* @__PURE__ */ l("path", {
  class: "opacity-75",
  fill: "currentColor",
  d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
}, null, -1), Ne = [
  De,
  qe
], Me = { key: 1 }, Ve = /* @__PURE__ */ O({
  __name: "Button",
  props: {
    text: { default: "" },
    type: { default: "default" },
    size: { default: "md" },
    icon: { default: "" },
    rounded: { type: Boolean, default: !1 },
    disabled: { type: Boolean, default: !1 },
    loading: { type: Boolean, default: !1 },
    loadingText: { default: "Loading..." },
    themed: { type: Boolean, default: !1 },
    centered: { type: Boolean, default: !1 },
    isSubmit: { type: Boolean, default: !1 },
    as: { default: "button" }
  },
  emits: ["click"],
  setup(e, { emit: o }) {
    const t = e;
    function a(n) {
      if (t.disabled || t.loading)
        return null;
      o("click", n);
    }
    return (n, r) => (s(), E(N(n.as), {
      onClick: r[0] || (r[0] = (i) => a(i)),
      type: n.isSubmit ? "submit" : "button",
      class: C([{
        "!opacity-50": t.disabled || t.loading,
        "text-theme-aware-neutral-500 bg-theme-500 hover:bg-theme-600 border-transparent focus:ring-theme": t.type === "primary" && t.themed,
        "dark:text-theme-500 text-theme-600 bg-theme-500 bg-opacity-10 hover:bg-opacity-20 dark:border-theme-500 border-theme-600 border-opacity-30 focus:ring-theme-500": t.type === "secondary" && t.themed,
        "text-dynamic-neutral-700 dark:text-dynamic-neutral-200 bg-dynamic-neutral-light dark:bg-dynamic-neutral-900 dark:hover:bg-dynamic-neutral-800 hover:bg-dynamic-neutral-50 border-dynamic-neutral-300 dark:border-dynamic-neutral-600 focus:ring-theme border": t.type === "default" && t.themed,
        "text-dynamic-neutral-light bg-indigo-600 hover:bg-indigo-700 border-transparent focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-400": t.type === "primary" && !t.themed,
        "text-indigo-500 bg-indigo-500 bg-opacity-10 hover:bg-opacity-20 border-transparent focus:ring-indigo-500": t.type === "secondary" && !t.themed,
        "text-dynamic-neutral-700 dark:text-dynamic-neutral-200 bg-dynamic-neutral-light dark:bg-dynamic-neutral-900 dark:hover:bg-dynamic-neutral-800 hover:bg-dynamic-neutral-50 border-dynamic-neutral-300 dark:border-dynamic-neutral-600 focus:ring-indigo-500 border": t.type === "default" && !t.themed,
        "px-2.5 py-1.5 text-xs": t.size === "xs" && !t.rounded,
        "px-3 py-2 text-sm": t.size === "sm" && !t.rounded,
        "px-4 py-2 text-sm": t.size === "md" && !t.rounded,
        "px-4 py-2 text-base": t.size === "lg" && !t.rounded,
        "px-6 py-3 text-base": t.size === "xl" && !t.rounded,
        "h-6 w-6 text-base items-center justify-center": t.rounded && t.size === "xs",
        "h-8 w-8 text-base items-center justify-center": t.rounded && t.size === "sm",
        "h-10 w-10 text-base items-center justify-center": t.rounded && t.size === "md",
        "h-12 w-12 text-base items-center justify-center": t.rounded && t.size === "lg",
        "h-16 w-16 text-base items-center justify-center": t.rounded && t.size === "xl",
        "rounded-full": t.rounded,
        rounded: !t.rounded && t.size === "xs",
        "rounded-md": !t.rounded && ["sm", "md", "lg", "xl"].includes(t.size),
        "justify-center": n.centered
      }, "inline-flex items-center overflow-auto font-medium border shadow-sm focus:ring-offset-white dark:focus:ring-offset-neutral-900 focus:outline-none focus:ring-2 focus:ring-offset-2"]),
      disabled: t.disabled
    }, {
      default: M(() => [
        t.icon ? (s(), d("span", _e, [
          t.loading ? (s(), d("svg", Ue, Ne)) : (s(), d("i", {
            key: 0,
            class: C(t.icon)
          }, null, 2))
        ])) : f("", !0),
        t.text !== "" || t.loadingText == "" ? (s(), d("span", Me, w(t.loading && t.loadingText ? t.loadingText : t.text), 1)) : f("", !0)
      ]),
      _: 1
    }, 8, ["type", "class", "disabled"]));
  }
}), Ze = { class: "relative z-0 inline-flex rounded-md shadow-sm" }, Fe = ["onClick"], We = {
  key: 0,
  class: "inline-flex items-center justify-center w-5 h-5 mr-1 text-dynamic-neutral-400 dark:text-dynamic-neutral-600"
}, Nn = /* @__PURE__ */ O({
  __name: "ButtonGroup",
  props: {
    buttons: { default: () => [] }
  },
  emits: ["click"],
  setup(e, { emit: o }) {
    const t = e;
    function a(n, r) {
      if (t.buttons[r].disabled)
        return null;
      o("click", { event: n, buttonIndex: r });
    }
    return (n, r) => (s(), d("span", Ze, [
      (s(!0), d(I, null, _(t.buttons, (i, m) => (s(), d("button", {
        key: m,
        onClick: (h) => a(h, m),
        type: "button",
        class: C([{
          " bg-dynamic-neutral-light text-dynamic-neutral-700 dark:text-dynamic-neutral-200 border-dynamic-neutral-300 dark:border-dynamic-neutral-600 dark:bg-dynamic-neutral-900 hover:bg-dynamic-neutral-50 dark:hover:bg-dynamic-neutral-800": !i.isActive,
          "bg-theme-500 text-theme-aware-neutral-500 border-theme-500 hover:bg-theme-600": i.isActive
        }, "relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border first:rounded-l-md last:rounded-r-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-theme-500 focus:border-theme-500"])
      }, [
        i.icon ? (s(), d("span", We, [
          l("i", {
            class: C([i.icon, "baseline"])
          }, null, 2)
        ])) : f("", !0),
        l("span", null, w(i.text), 1)
      ], 10, Fe))), 128))
    ]));
  }
}), Qe = /* @__PURE__ */ O({
  __name: "Card",
  props: {
    rounded: { type: Boolean, default: !1 },
    padded: { type: Boolean, default: !0 },
    asElement: { default: "div" },
    position: { default: "relative" }
  },
  setup(e, { expose: o }) {
    const t = e, a = P(null);
    return o({
      rootRef: a
    }), (n, r) => (s(), E(N(n.asElement), se(n.$attrs, {
      class: [{
        "!rounded-md": t.rounded,
        position: n.position
      }, "overflow-hidden bg-dynamic-neutral-light rounded-sm ui-item dark:bg-dynamic-neutral-900"],
      ref_key: "rootRef",
      ref: a
    }), {
      default: M(() => [
        l("div", {
          class: C(["w-full h-full", { "px-4 py-5 sm:p-6": t.padded }])
        }, [
          q(n.$slots, "default")
        ], 2)
      ]),
      _: 3
    }, 16, ["class"]));
  }
}), Ge = (e, o) => {
  const t = e.__vccOpts || e;
  for (const [a, n] of o)
    t[a] = n;
  return t;
}, Ke = {}, Ye = { class: "centered-container" };
function Je(e, o) {
  return s(), d("div", Ye, [
    q(e.$slots, "default")
  ]);
}
const Mn = /* @__PURE__ */ Ge(Ke, [["render", Je]]), Xe = { class: "space-y-2 md:space-y-1" }, et = {
  key: 0,
  class: "h-[1px] mx-2 bg-dynamic-neutral-100 dark:bg-dynamic-neutral-800"
}, tt = { class: "relative flex flex-col" }, nt = {
  key: 0,
  class: "text-lg"
}, ot = { key: 1 }, at = ["onClick"], lt = { class: "mr-2" }, ce = /* @__PURE__ */ O({
  __name: "ContextMenu",
  props: {
    config: {},
    verticalPosition: {},
    horizontalPosition: {}
  },
  emits: ["closeContextTap"],
  setup(e, { emit: o }) {
    const t = e;
    function a(n) {
      n.stopPropagation(), o("closeContextTap");
    }
    return (n, r) => (s(), d("div", {
      class: "fixed top-0 left-0 z-50 flex flex-col items-center justify-center w-full h-full glass-blur bg-dynamic-neutral-dark/20 md:bg-dynamic-neutral-dark/0",
      onClick: a
    }, [
      H(Qe, {
        padded: !1,
        style: Oe({
          "--context-position-x": t.horizontalPosition + "px",
          "--context-position-y": t.verticalPosition + "px"
        }),
        class: "!absolute md:!left-[var(--context-position-x)] md:!top-[var(--context-position-y)] md:!fixed bottom-0 left-0 w-full max-w-full rounded-t-lg rounded-b-none md:rounded-md p-2 md:p-1 pt-3 pb-5-safe md:bottom-auto md:left-auto md:w-64 text-dynamic-neutral-600 dark:text-dynamic-neutral-300 !shadow-xl md:border border-dynamic-neutral-100 dark:border-dynamic-neutral-800 dark:shadow-black"
      }, {
        default: M(() => [
          l("div", Xe, [
            n.config && n.config.lists ? (s(!0), d(I, { key: 0 }, _(n.config.lists, (i, m) => (s(), d(I, { key: m }, [
              m !== 0 ? (s(), d("div", et)) : f("", !0),
              l("div", tt, [
                i.label ? (s(), d("h4", nt, w(i.label), 1)) : f("", !0),
                i.description ? (s(), d("section", ot, w(i.description), 1)) : f("", !0),
                l("section", {
                  class: C([{
                    "flex-col space-y-1": i.direction !== "horizontal",
                    "flex-row pb-3": i.direction === "horizontal"
                  }, "relative flex w-full max-w-full"])
                }, [
                  (s(!0), d(I, null, _(i.items, (h, g) => (s(), d(I, { key: g }, [
                    h.show ? (s(), d("button", {
                      key: 0,
                      class: C([{
                        "flex-col rounded-md items-center min-w-[8rem]": i.direction === "horizontal",
                        "flex-row rounded-md": i.direction !== "horizontal"
                      }, "flex px-4 py-2 text-sm cursor-pointer xs:text-lg lg:text-base hover:bg-dynamic-neutral-100 dark:hover:bg-dynamic-neutral-800"]),
                      onClick: h.action
                    }, [
                      l("span", lt, [
                        l("i", {
                          class: C(h.icon)
                        }, null, 2)
                      ]),
                      l("span", null, w(h.label), 1)
                    ], 10, at)) : f("", !0)
                  ], 64))), 128))
                ], 2)
              ])
            ], 64))), 128)) : f("", !0)
          ])
        ]),
        _: 1
      }, 8, ["style"])
    ]));
  }
}), rt = { class: "flex flex-col input-component-wrapper" }, st = {
  key: 0,
  class: "flex justify-between mb-2"
}, it = ["for"], dt = {
  key: 1,
  class: "text-sm text-dynamic-neutral-500 dark:text-dynamic-neutral-100",
  id: "email-optional"
}, ut = { class: "relative flex flex-row items-start justify-start flex-grow" }, ct = ["id", "name", "placeholder", "type", "value"], pt = {
  key: 1,
  class: "mt-2 text-sm text-dynamic-neutral-500",
  id: "email-description"
}, J = /* @__PURE__ */ O({
  __name: "BaseInput",
  props: {
    modelValue: {
      type: String,
      required: !1,
      default: () => ""
    },
    name: {
      type: String,
      required: !1,
      default: () => ""
    },
    type: {
      type: String,
      default: () => "text"
    },
    placeholder: {
      type: String,
      default: () => ""
    },
    leadingIcon: {
      type: String,
      required: !1
    },
    trailingIcon: {
      type: String,
      required: !1
    },
    boldIcons: {
      type: Boolean,
      required: !1,
      default: () => !1
    },
    leadingButton: {
      type: Object,
      required: !1
    },
    trailingButton: {
      type: Object,
      required: !1
    },
    label: {
      type: String,
      required: !1
    },
    helperText: {
      type: String,
      required: !1
    },
    hintText: {
      type: String,
      required: !1
    },
    id: {
      type: String,
      required: !0
    },
    fullWidth: {
      type: Boolean,
      required: !1
    },
    hasTintOpacity: {
      type: Boolean,
      required: !1
    }
  },
  emits: ["update:modelValue"],
  setup(e, { emit: o }) {
    const t = (a) => {
      o("update:modelValue", a.target.value);
    };
    return (a, n) => (s(), d("div", rt, [
      e.label || e.hintText ? (s(), d("div", st, [
        e.label ? (s(), d("label", {
          key: 0,
          for: e.id,
          class: "block text-sm font-medium text-dynamic-neutral-700 dark:text-dynamic-neutral-200"
        }, w(e.label), 9, it)) : f("", !0),
        e.hintText ? (s(), d("span", dt, w(e.hintText), 1)) : f("", !0)
      ])) : f("", !0),
      l("div", {
        class: C(["flex flex-row input-wrapper", { "w-full": e.fullWidth }])
      }, [
        e.leadingButton ? (s(), d("button", {
          key: 0,
          onClick: n[0] || (n[0] = //@ts-ignore
          (...r) => e.leadingButton?.tapHandler && e.leadingButton?.tapHandler(...r)),
          type: "button",
          class: "relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium border text-dynamic-neutral-700 dark:text-dynamic-neutral-300 bg-dynamic-neutral-100 dark:bg-dynamic-neutral-800 hover:dark:bg-dynamic-neutral-700 border-dynamic-neutral-300 dark:border-dynamic-neutral-700 rounded-l-md hover:bg-dynamic-neutral-200 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        }, [
          e.leadingButton.icon ? (s(), d("i", {
            key: 0,
            class: C([
              e.leadingButton.icon,
              e.leadingButton.boldIcons ? "fas" : "far"
            ])
          }, null, 2)) : f("", !0),
          l("span", null, w(e.leadingButton.text), 1)
        ])) : f("", !0),
        l("div", ut, [
          e.leadingIcon ? (s(), d("i", {
            key: 0,
            class: C(["leading-icon far", [e.leadingIcon, e.boldIcons ? "fas" : "far"]])
          }, null, 2)) : f("", !0),
          l("input", se({
            "data-testid": "input-element",
            id: e.label,
            name: e.name,
            class: [{
              "pl-10": !!e.leadingIcon,
              "pr-10": !!e.trailingIcon,
              "rounded-l-none": e.leadingButton,
              "rounded-r-none": e.trailingButton,
              "bg-tint-opacity-70": e.hasTintOpacity
            }, "block w-full px-3 py-2 text-sm border rounded-md bg-dynamic-neutral-50 placeholder-neutral-400 dark:placeholder-neutral-600 border-dynamic-neutral-200 dark:border-dynamic-neutral-700 dark:bg-dynamic-neutral-950 dark:bg-opacity-50 focus:outline-none text-dynamic-neutral-600 dark:text-dynamic-neutral-300 focus:placeholder-neutral-500 focus:ring-1 focus:ring-theme-600 focus:border-theme-600 dark:focus:ring-theme-500 dark:focus:border-theme-500 sm:text-sm"],
            placeholder: e.placeholder,
            type: e.type,
            value: e.modelValue,
            onInput: t
          }, a.$attrs), null, 16, ct),
          e.trailingIcon ? (s(), d("i", {
            key: 1,
            class: C(["absolute text-dynamic-neutral-500 top-2.5 far right-2.5", e.trailingIcon])
          }, null, 2)) : f("", !0)
        ]),
        e.trailingButton ? (s(), d("button", {
          key: 1,
          onClick: n[1] || (n[1] = //@ts-ignore
          (...r) => e.trailingButton?.tapHandler && e.trailingButton?.tapHandler(...r)),
          type: "button",
          class: "relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium border text-dynamic-neutral-700 dark:text-dynamic-neutral-300 bg-dynamic-neutral-100 dark:bg-dynamic-neutral-800 border-dynamic-neutral-300 dark:border-dynamic-neutral-700 rounded-r-md hover:bg-dynamic-neutral-200 hover:dark:bg-dynamic-neutral-700 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        }, [
          e.trailingButton.icon ? (s(), d("i", {
            key: 0,
            class: C([
              e.trailingButton.icon,
              e.trailingButton.boldIcons ? "fas" : "far"
            ])
          }, null, 2)) : f("", !0),
          l("span", null, w(e.trailingButton.text), 1)
        ])) : f("", !0)
      ], 2),
      e.helperText ? (s(), d("p", pt, w(e.helperText), 1)) : f("", !0)
    ]));
  }
});
const ft = { class: "flex justify-between xs:flex-row text-dynamic-neutral-500 dark:text-dynamic-neutral-400" }, mt = { class: "relative flex justify-between xs:flex-row -left-2 xs:-left-3" }, ht = {
  key: 0,
  class: "far fa-heart"
}, gt = {
  key: 1,
  class: "fas fa-heart"
}, yt = { key: 2 }, bt = /* @__PURE__ */ l("i", { class: "far fa-comments" }, null, -1), kt = { class: "flex flex-row" }, vt = { key: 0 }, xt = /* @__PURE__ */ l("span", { class: "hidden xs:!flex" }, "replies", -1), wt = /* @__PURE__ */ l("i", { class: "far fa-arrow-up-from-bracket" }, null, -1), $t = { key: 0 }, pe = /* @__PURE__ */ O({
  __name: "PostActionSection",
  props: {
    post: {},
    bookmarked: { type: Boolean },
    reacted: { type: Boolean }
  },
  emits: ["reactionTap", "replyTap", "shareTap", "bookmarkTap"],
  setup(e, { emit: o }) {
    return (t, a) => (s(), d("div", ft, [
      l("span", mt, [
        l("a", {
          "data-testid": "reaction-button",
          onClick: a[0] || (a[0] = A((n) => o("reactionTap"), ["stop"])),
          class: C([{
            "text-red-500": t.reacted,
            "hover:text-red-400": !t.reacted
          }, "flex items-center px-3 py-1 space-x-2 transition-all bg-red-200 bg-opacity-0 rounded-full xs:px-4 xs:py-2 xs:justify-center pointer-fine:hover:bg-opacity-25 hover:text-red-500"])
        }, [
          t.reacted ? (s(), d("i", gt)) : (s(), d("i", ht)),
          t.post.reaction_count ? (s(), d("span", yt, w(t.post.reaction_count), 1)) : f("", !0)
        ], 2),
        l("a", {
          "data-testid": "reply-button",
          onClick: a[1] || (a[1] = A((n) => o("replyTap"), ["stop"])),
          class: "relative flex items-center px-3 py-1 space-x-2 transition-all bg-orange-200 bg-opacity-0 rounded-full xs:px-4 xs:py-2 xs:justify-center pointer-fine:hover:bg-opacity-25 hover:text-orange-500"
        }, [
          bt,
          l("span", kt, [
            t.post.reply_count ? (s(), d("span", vt, w(t.post.reply_count) + " ", 1)) : f("", !0),
            xt
          ])
        ])
      ]),
      l("a", {
        "data-testid": "share-button",
        onClick: a[2] || (a[2] = A((n) => o("shareTap", n), ["stop"])),
        class: "flex items-center px-3 py-1 space-x-2 transition-all bg-green-200 bg-opacity-0 rounded-full xs:px-4 xs:py-2 xs:justify-center pointer-fine:hover:bg-opacity-25 hover:text-green-500"
      }, [
        wt,
        t.post.share_count ? (s(), d("span", $t, w(t.post.share_count), 1)) : f("", !0)
      ])
    ]));
  }
});
var X;
const fe = typeof window < "u", zt = (e) => typeof e == "string", me = () => {
}, Tt = fe && ((X = window?.navigator) == null ? void 0 : X.userAgent) && /iP(ad|hone|od)/.test(window.navigator.userAgent);
function he(e) {
  return typeof e == "function" ? e() : j(e);
}
function Ct(e) {
  return e;
}
function Pt(e) {
  return Se() ? (je(e), !0) : !1;
}
function R(e) {
  var o;
  const t = he(e);
  return (o = t?.$el) != null ? o : t;
}
const ge = fe ? window : void 0;
function F(...e) {
  let o, t, a, n;
  if (zt(e[0]) || Array.isArray(e[0]) ? ([t, a, n] = e, o = ge) : [o, t, a, n] = e, !o)
    return me;
  Array.isArray(t) || (t = [t]), Array.isArray(a) || (a = [a]);
  const r = [], i = () => {
    r.forEach((y) => y()), r.length = 0;
  }, m = (y, z, p, b) => (y.addEventListener(z, p, b), () => y.removeEventListener(z, p, b)), h = ie(() => [R(o), he(n)], ([y, z]) => {
    i(), y && r.push(...t.flatMap((p) => a.map((b) => m(y, p, b, z))));
  }, { immediate: !0, flush: "post" }), g = () => {
    h(), i();
  };
  return Pt(g), g;
}
let ee = !1;
function ye(e, o, t = {}) {
  const { window: a = ge, ignore: n = [], capture: r = !0, detectIframe: i = !1 } = t;
  if (!a)
    return;
  Tt && !ee && (ee = !0, Array.from(a.document.body.children).forEach((p) => p.addEventListener("click", me)));
  let m = !0;
  const h = (p) => n.some((b) => {
    if (typeof b == "string")
      return Array.from(a.document.querySelectorAll(b)).some(($) => $ === p.target || p.composedPath().includes($));
    {
      const $ = R(b);
      return $ && (p.target === $ || p.composedPath().includes($));
    }
  }), y = [
    F(a, "click", (p) => {
      const b = R(e);
      if (!(!b || b === p.target || p.composedPath().includes(b))) {
        if (p.detail === 0 && (m = !h(p)), !m) {
          m = !0;
          return;
        }
        o(p);
      }
    }, { passive: !0, capture: r }),
    F(a, "pointerdown", (p) => {
      const b = R(e);
      b && (m = !p.composedPath().includes(b) && !h(p));
    }, { passive: !0 }),
    i && F(a, "blur", (p) => {
      var b;
      const $ = R(e);
      ((b = a.document.activeElement) == null ? void 0 : b.tagName) === "IFRAME" && !$?.contains(a.document.activeElement) && o(p);
    })
  ].filter(Boolean);
  return () => y.forEach((p) => p());
}
const te = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {}, ne = "__vueuse_ssr_handlers__";
te[ne] = te[ne] || {};
var oe;
(function(e) {
  e.UP = "UP", e.RIGHT = "RIGHT", e.DOWN = "DOWN", e.LEFT = "LEFT", e.NONE = "NONE";
})(oe || (oe = {}));
var Bt = Object.defineProperty, ae = Object.getOwnPropertySymbols, Ot = Object.prototype.hasOwnProperty, St = Object.prototype.propertyIsEnumerable, le = (e, o, t) => o in e ? Bt(e, o, { enumerable: !0, configurable: !0, writable: !0, value: t }) : e[o] = t, jt = (e, o) => {
  for (var t in o || (o = {}))
    Ot.call(o, t) && le(e, t, o[t]);
  if (ae)
    for (var t of ae(o))
      St.call(o, t) && le(e, t, o[t]);
  return e;
};
const Et = {
  easeInSine: [0.12, 0, 0.39, 0],
  easeOutSine: [0.61, 1, 0.88, 1],
  easeInOutSine: [0.37, 0, 0.63, 1],
  easeInQuad: [0.11, 0, 0.5, 0],
  easeOutQuad: [0.5, 1, 0.89, 1],
  easeInOutQuad: [0.45, 0, 0.55, 1],
  easeInCubic: [0.32, 0, 0.67, 0],
  easeOutCubic: [0.33, 1, 0.68, 1],
  easeInOutCubic: [0.65, 0, 0.35, 1],
  easeInQuart: [0.5, 0, 0.75, 0],
  easeOutQuart: [0.25, 1, 0.5, 1],
  easeInOutQuart: [0.76, 0, 0.24, 1],
  easeInQuint: [0.64, 0, 0.78, 0],
  easeOutQuint: [0.22, 1, 0.36, 1],
  easeInOutQuint: [0.83, 0, 0.17, 1],
  easeInExpo: [0.7, 0, 0.84, 0],
  easeOutExpo: [0.16, 1, 0.3, 1],
  easeInOutExpo: [0.87, 0, 0.13, 1],
  easeInCirc: [0.55, 0, 1, 0.45],
  easeOutCirc: [0, 0.55, 0.45, 1],
  easeInOutCirc: [0.85, 0, 0.15, 1],
  easeInBack: [0.36, 0, 0.66, -0.56],
  easeOutBack: [0.34, 1.56, 0.64, 1],
  easeInOutBack: [0.68, -0.6, 0.32, 1.6]
};
jt({
  linear: Ct
}, Et);
const At = {
  key: 0,
  class: "absolute w-full h-full"
}, It = /* @__PURE__ */ l("linearGradient", {
  id: "linearColors",
  x1: "0",
  y1: "0",
  x2: "1",
  y2: "1"
}, [
  /* @__PURE__ */ l("stop", {
    offset: "50%",
    class: "stop-theme-color"
  }),
  /* @__PURE__ */ l("stop", {
    offset: "100%",
    class: "stop-theme-accent-color"
  })
], -1), Lt = ["r"], Ht = ["src", "alt"], Rt = /* @__PURE__ */ l("span", null, [
  /* @__PURE__ */ l("i", { class: "far fa-user" })
], -1), _t = [
  Rt
], be = /* @__PURE__ */ O({
  __name: "ProfilePhoto",
  props: {
    imageUrl: { default: "" },
    size: { default: "md" },
    accessibleText: {},
    placeholderText: {},
    shape: { default: "circle" },
    showHalo: { type: Boolean, default: !1 }
  },
  emits: ["tap"],
  setup(e, { emit: o }) {
    const t = e, a = S(() => t.size === "xs" ? 10 : t.size === "sm" ? 15 : t.size === "md" ? 19 : t.size === "lg" ? 30 : t.size === "xl" ? 45 : 19);
    return (n, r) => (s(), d("div", {
      onClick: r[0] || (r[0] = (i) => o("tap")),
      class: C(["box-border relative flex items-center justify-center flex-shrink-0", {
        "w-6 h-6": n.size == "xs",
        "w-8 h-8": n.size === "sm",
        "w-10 h-10": n.size === "md",
        "w-16 h-16": n.size === "lg",
        "w-24 h-24": n.size === "xl",
        "w-36 h-36": n.size === "2xl",
        "w-48 h-48": n.size === "3xl",
        "w-64 h-64": n.size === "4xl"
      }])
    }, [
      n.shape === "circle" && n.showHalo ? (s(), d("svg", At, [
        It,
        l("circle", {
          r: a.value,
          cx: "50%",
          cy: "50%",
          "stroke-width": "1.5",
          fill: "none",
          stroke: "url(#linearColors)"
        }, null, 8, Lt)
      ])) : f("", !0),
      l("div", {
        class: C(["relative flex", {
          "w-4 h-4": n.size == "xs" && n.showHalo,
          "w-6 h-6": n.size == "xs" && !n.showHalo,
          "w-6 h-6 ": n.size === "sm" && n.showHalo,
          "w-8 h-8": n.size === "sm" && !n.showHalo,
          "w-8 h-8 ": n.size === "md" && n.showHalo,
          "w-10 h-10": n.size === "md" && !n.showHalo,
          "w-14 h-14 ": n.size === "lg" && n.showHalo,
          "w-16 h-16": n.size === "lg" && !n.showHalo,
          "w-20 h-20": n.size === "xl" && n.showHalo,
          "w-24 h-24": n.size === "xl" && !n.showHalo,
          "w-32 h-32": n.size === "2xl" && n.showHalo,
          "w-36 h-36": n.size === "2xl" && !n.showHalo,
          "w-44 h-44": n.size === "3xl" && n.showHalo,
          "w-48 h-48 ": n.size === "3xl" && !n.showHalo,
          "w-60 h-60": n.size === "4xl" && n.showHalo,
          "w-64 h-64 ": n.size === "4xl" && !n.showHalo
        }])
      }, [
        n.imageUrl ? (s(), d("img", {
          key: 0,
          class: C(["object-cover w-full h-full", {
            "rounded-full": n.shape === "circle",
            "rounded-sm": n.shape === "square" && (n.size === "sm" || n.size == "xs"),
            "rounded-md": n.shape === "square" && n.size === "md",
            "rounded-lg": n.shape === "square" && n.size === "lg",
            "rounded-xl": n.shape === "square" && n.size === "xl"
          }]),
          src: n.imageUrl,
          alt: n.accessibleText ?? "",
          loading: "lazy"
        }, null, 10, Ht)) : n.placeholderText ? (s(), d("div", {
          key: 1,
          class: C([{
            "text-xs": n.size === "sm" || n.size === "xs",
            "text-lg": n.size === "md",
            "text-xl": n.size === "lg",
            "text-3xl": n.size === "xl",
            "text-4xl": n.size === "2xl",
            "text-5xl": n.size === "3xl",
            "text-7xl": n.size === "4xl",
            "rounded-full": n.shape === "circle",
            "rounded-sm": n.shape === "square" && (n.size === "sm" || n.size == "xs"),
            "rounded-md": n.shape === "square" && n.size === "md",
            "rounded-lg": n.shape === "square" && n.size === "lg",
            "rounded-xl": n.shape === "square" && n.size === "xl"
          }, "flex items-center justify-center w-full h-full text-theme-aware-neutral-500 bg-dynamic-neutral-100 bg-theme-500"])
        }, [
          l("span", null, w(n.size !== "xs" && n.size !== "sm" ? n.placeholderText : n.placeholderText.charAt(0)), 1)
        ], 2)) : (s(), d("div", {
          key: 2,
          class: C([{
            "text-xs": n.size === "sm" || n.size == "xs",
            "text-lg": n.size === "md",
            "text-xl": n.size === "lg",
            "text-3xl": n.size === "xl",
            "rounded-full": n.shape === "circle",
            "rounded-sm": n.shape === "square" && (n.size === "sm" || n.size == "xs"),
            "rounded-md": n.shape === "square" && n.size === "md",
            "rounded-lg": n.shape === "square" && n.size === "lg",
            "rounded-xl": n.shape === "square" && n.size === "xl"
          }, "flex items-center justify-center w-full h-full bg-theme-500 text-dynamic-neutral-100"])
        }, _t, 2))
      ], 2)
    ], 2));
  }
}), Ut = { class: "relative flex flex-row items-center justify-between space-x-2 text-sm font-medium dark:text-dynamic-neutral-200 text-dynamic-neutral-900" }, Dt = { class: "box-border flex flex-col flex-grow pr-3 space-y-1" }, qt = { class: "flex flex-col xs:block" }, Nt = { class: "text-dynamic-neutral-500 dark:text-dynamic-neutral-400" }, Mt = { key: 1 }, ke = /* @__PURE__ */ O({
  __name: "PostHeader",
  props: {
    user: {},
    location: {},
    network: {},
    readableDate: {},
    bookmarked: { type: Boolean, default: !1 },
    withProfile: { type: Boolean, default: !1 },
    showContextMenu: { type: Boolean, default: !0 }
  },
  emits: [
    "bookmarkTap",
    "contextMenuTap",
    "userTap",
    "networkTap"
  ],
  setup(e, { emit: o }) {
    const t = L("appConfig", {});
    return (a, n) => {
      const r = be;
      return s(), d("div", null, [
        l("p", Ut, [
          a.withProfile ? (s(), E(r, {
            key: 0,
            "placeholder-text": a.user.acronym,
            "image-url": a.user.profile_photo ? j(t).content_url + "profile/small/" + a.user.profile_photo.filename : "",
            class: "hidden bg-opacity-100 xs:flex"
          }, null, 8, ["placeholder-text", "image-url"])) : f("", !0),
          l("span", Dt, [
            l("span", qt, [
              l("button", {
                "data-testid": "user-name",
                onClick: n[0] || (n[0] = A((i) => o("userTap"), ["stop"])),
                href: "#",
                class: "inline p-0 break-words bg-transparent border-none"
              }, w(a.user.name), 1),
              a.withProfile ? f("", !0) : (s(), d("button", {
                key: 0,
                "data-testid": "user-username",
                onClick: n[1] || (n[1] = A((i) => o("userTap"), ["stop"])),
                class: "p-0 bg-transparent border-none text-dynamic-neutral-500 dark:text-dynamic-neutral-400"
              }, " @" + w(a.user.username), 1))
            ]),
            l("span", Nt, [
              a.withProfile ? (s(), d("button", {
                key: 0,
                "data-testid": "user-username",
                onClick: n[2] || (n[2] = A((i) => o("userTap"), ["stop"])),
                class: "p-0 bg-transparent border-none text-dynamic-neutral-500 dark:text-dynamic-neutral-400"
              }, " @" + w(a.user.username), 1)) : f("", !0),
              l("button", {
                "data-testid": "user-username",
                class: "p-0 bg-transparent border-none text-dynamic-neutral-500 dark:text-dynamic-neutral-400",
                onClick: n[3] || (n[3] = A((i) => o("networkTap"), ["stop"]))
              }, w(a.network ? a.withProfile ? " • " + a.network.title : a.network.title + " • " : ""), 1),
              a.withProfile ? f("", !0) : (s(), d("span", Mt, w(a.readableDate), 1))
            ])
          ])
        ])
      ]);
    };
  }
});
const Vt = /* @__PURE__ */ l("i", { class: "far fa-comments" }, null, -1), Zt = { class: "flex flex-row flex-grow max-w-full p-2 transition-all bg-opacity-0 cursor-pointer xs:space-x-3 xs:p-4 bg-neutral-400 pointer-fine:dark:hover:bg-opacity-10 pointer-fine:hover:bg-opacity-5 border-spacing-0" }, Ft = { class: "flex-1 min-w-0 space-y-2 post-content" }, Wt = {
  "data-testid": "post-text",
  class: "xs:text-base text-dynamic-neutral-700 dark:text-dynamic-neutral-300 line-clamp-6"
}, Qt = {
  key: 1,
  class: "relative inline-block scroll-pl-16 pl-16 space-x-2 overflow-x-scroll h-96 whitespace-nowrap snap-x -left-16 w-[calc(100%+4.8rem)] align-middle hide-scrollbar"
}, Gt = ["src"], Vn = /* @__PURE__ */ O({
  __name: "PostFeedItem",
  props: {
    post: {},
    user: {},
    bookmarked: { type: Boolean },
    reacted: { type: Boolean },
    photos: {},
    links: {}
  },
  emits: [
    "bookmark-tap",
    "reaction-tap",
    "reply-tap",
    "share-tap",
    "post-tap",
    "context-menu-tap",
    "pin-tap",
    "delete-tap",
    "copy-link-tap",
    "report-tap",
    "reply-to-tap",
    "user-tap",
    "network-tap"
  ],
  setup(e, { emit: o }) {
    const t = e, a = L("auth_id", null), n = L(
      "appConfig",
      P({})
    ), r = P({ x: 0, y: 0 }), i = S(function() {
      if (t.links && t.links.length > 0 && n.value.web_base_url) {
        let c = t.post.text;
        const k = n.value.web_base_url;
        return t.links.forEach(function(B) {
          const Z = B.url.replace(
            /(([a-zA-Z0-9]+:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,4})(:[0-9]+)?((\/.{0,14}).*)?)/g,
            "$2$4$5..."
          );
          c = c.replace(
            B.url,
            `<a href="${k}/away/${B?.id}" clas v-on:click.stop rel="noreferrer" target="_blank">${Z}</a>`
          );
        }), c;
      }
      return t.post.text;
    }), m = S(() => ({
      template: i.value.replace(/{/g, "&lcub;").replace(/}/g, "&rcub;").replace(/\[/g, "&lbrack").replace(/\]/g, "&rbrack").replace(/\n/g, "<br/>").replace(/!!([^!]+)!!/g, '<span class="italic">$1</span>').replace(/__([^_]+)__/g, '<span class="underline">$1</span>').replace(/~~([^~]+)~~/g, '<span class="line-through">$1</span>').replace(/\*\*([^*]+)\*\*/g, '<span class="font-bold">$1</span>').replace(
        /``([^`]+)``/g,
        '<span class="border rounded-md py-0.5 px-1 border-theme-500 bg-theme-100 text-theme-500">$1</span>'
      ).replace(
        /#([a-zA-Z0-9_]+)/g,
        `<RouterLink v-on:click.stop v-bind:to="{name: 'tag', params: { tagId: '$1' }}" class="font-semibold text-theme-600 dark:text-theme-300 tint-rate-75">#$1</RouterLink>`
      ).replace(
        // /(?<!\w)@([a-zA-Z0-9_]+)(?!\w|$)/g,
        /@(\w+)\b/g,
        `<RouterLink v-on:click.stop v-bind:to="{name: 'profile', params: { username: '$1'}}" class="font-semibold text-theme-600 dark:text-theme-300 tint-rate-75">@$1</RouterLink>`
      ),
      name: "PostTextContent"
    })), h = P(!1), g = P(null);
    function y() {
      console.log("emitting pin Post event..."), o("pin-tap", t.post.id);
    }
    function z() {
      console.log("emitting delete Post event..."), confirm(
        `Are you sure you want to delete this post ${t.post.id}`
      ) && o("delete-tap", t.post.id);
    }
    function p() {
      console.log("emitting report Post event..."), o("report-tap", t.post.id);
    }
    function b() {
      navigator.clipboard ? (navigator.clipboard.writeText(
        window.location.origin + "/post/" + t.post.id
      ), console.log("emitting copy link event..."), o("copy-link-tap", t.post.id)) : console.log("Clipboard access is limited.");
    }
    function $(c) {
      o("reply-to-tap", c.id);
    }
    async function T() {
      o("share-tap", t.post.id);
      const c = {
        title: `Post by ${t.user?.name}`,
        text: t.post.text,
        url: window.location.origin + "/post/" + t.post.id
      };
      try {
        await window.navigator.share(c);
      } catch (k) {
        console.log(k);
      }
    }
    function v() {
      h.value = !h.value;
    }
    ye(g.value, () => console.log("Click outside"));
    const u = S(() => ({
      lists: [
        {
          items: [
            {
              label: "Pin",
              icon: "fas fa-thumbtack",
              action: y,
              show: a === t.post.user_id
            },
            {
              label: "Link",
              icon: "fas fa-link",
              action: b,
              show: "clipboard" in navigator
            },
            {
              label: "Share via...",
              icon: "fas fa-share-alt",
              action: T,
              show: "share" in navigator
            },
            {
              label: "Delete",
              icon: "fas fa-trash",
              action: z,
              show: a === t.post.user_id
            },
            {
              label: "Report",
              icon: "fas fa-flag",
              action: p,
              show: !1
            }
          ]
        },
        {
          items: [
            {
              label: "Cancel",
              icon: "fas fa-times",
              show: !0,
              action: () => console.log(`cancel link ${t.post.id}`)
            }
          ]
        }
      ]
    })), x = S(() => u.value.lists.some((c, k) => u.value.lists.length == k + 1 ? !1 : c.items.some(function(B) {
      return B.show;
    })));
    return (c, k) => (s(), d(I, null, [
      c.post.reply_to_id !== null && c.post.reply_to ? (s(), d("a", {
        key: 0,
        onClick: k[0] || (k[0] = A((B) => $(c.post.reply_to), ["stop"])),
        class: "relative flex items-center px-4 py-2 space-x-1 text-xs font-medium leading-relaxed border-b cursor-pointer text-dynamic-neutral-400 hover:text-dynamic-neutral-500 dark:text-dynamic-neutral-400 dark:hover:text-dynamic-neutral-300 border-dynamic-neutral-100 dark:border-dynamic-neutral-800"
      }, [
        Vt,
        l("span", null, "Replying to @" + w(c.post.reply_to.user?.username), 1)
      ])) : f("", !0),
      l("a", {
        onClick: k[10] || (k[10] = A((B) => o("post-tap"), ["stop"])),
        class: "relative post-element"
      }, [
        (s(), E(de, { to: "body" }, [
          h.value ? (s(), E(ce, {
            key: 0,
            "horizontal-position": r.value.x,
            "vertical-position": r.value.y,
            config: u.value,
            onCloseContextTap: k[1] || (k[1] = (B) => h.value = !1),
            ref_key: "contextElementRef",
            ref: g
          }, null, 8, ["horizontal-position", "vertical-position", "config"])) : f("", !0)
        ])),
        l("div", Zt, [
          H(be, {
            "placeholder-text": c.user.acronym,
            class: "hidden bg-opacity-100 xs:flex",
            "image-url": c.user.profile_photo ? j(n).content_url + "profile/small/" + c.user.profile_photo.filename : ""
          }, null, 8, ["placeholder-text", "image-url"]),
          l("div", Ft, [
            c.post && c.user ? (s(), E(ke, {
              key: 0,
              user: c.user,
              "readable-date": c.post.created_at_readable ?? "",
              bookmarked: !!c.bookmarked,
              network: c.post.network ?? void 0,
              "show-context-menu": x.value,
              onBookmarkTap: k[2] || (k[2] = () => o("bookmark-tap", c.post.id)),
              onContextMenuTap: k[3] || (k[3] = (B) => {
                r.value.x = B.left, r.value.y = B.top, v();
              }),
              onUserTap: k[4] || (k[4] = () => o("user-tap")),
              onNetworkTap: k[5] || (k[5] = () => o("network-tap"))
            }, null, 8, ["user", "readable-date", "bookmarked", "network", "show-context-menu"])) : f("", !0),
            l("p", Wt, [
              (s(), E(N(m.value)))
            ]),
            c.post.photos?.length ? (s(), d("div", Qt, [
              (s(!0), d(I, null, _(c.post.photos || [], (B, Z) => (s(), d("div", {
                key: Z,
                class: "box-border relative inline-block w-auto h-full overflow-hidden align-middle rounded-lg bg-dynamic-neutral-500/20 snap-start"
              }, [
                l("img", {
                  class: "inline-flex object-cover object-center w-auto h-full max-h-full",
                  src: B ? j(n).content_url + "posts/large/" + B.filename : "",
                  alt: ""
                }, null, 8, Gt)
              ]))), 128))
            ])) : f("", !0),
            q(c.$slots, "default"),
            q(c.$slots, "links"),
            H(pe, {
              reacted: !!c.reacted,
              bookmarked: !!c.bookmarked,
              onBookmarkTap: k[6] || (k[6] = () => o("bookmark-tap", c.post.id)),
              onReactionTap: k[7] || (k[7] = () => o("reaction-tap", c.post.id)),
              onReplyTap: k[8] || (k[8] = () => o("reply-tap", c.post.id)),
              onShareTap: k[9] || (k[9] = (B) => o("share-tap", { postId: c.post.id, event: B })),
              post: c.post
            }, null, 8, ["reacted", "bookmarked", "post"])
          ])
        ])
      ])
    ], 64));
  }
});
function ve(e, o, ...t) {
  if (e in o) {
    let n = o[e];
    return typeof n == "function" ? n(...t) : n;
  }
  let a = new Error(`Tried to handle "${e}" but there is no handler defined. Only defined handlers are: ${Object.keys(o).map((n) => `"${n}"`).join(", ")}.`);
  throw Error.captureStackTrace && Error.captureStackTrace(a, ve), a;
}
var Kt = ((e) => (e[e.None = 0] = "None", e[e.RenderStrategy = 1] = "RenderStrategy", e[e.Static = 2] = "Static", e))(Kt || {}), Yt = ((e) => (e[e.Unmount = 0] = "Unmount", e[e.Hidden = 1] = "Hidden", e))(Yt || {});
function U({ visible: e = !0, features: o = 0, ourProps: t, theirProps: a, ...n }) {
  var r;
  let i = we(a, t), m = Object.assign(n, { props: i });
  if (e || o & 2 && i.static)
    return W(m);
  if (o & 1) {
    let h = (r = i.unmount) == null || r ? 0 : 1;
    return ve(h, { 0() {
      return null;
    }, 1() {
      return W({ ...n, props: { ...i, hidden: !0, style: { display: "none" } } });
    } });
  }
  return W(m);
}
function W({ props: e, attrs: o, slots: t, slot: a, name: n }) {
  var r, i;
  let { as: m, ...h } = $e(e, ["unmount", "static"]), g = (r = t.default) == null ? void 0 : r.call(t, a), y = {};
  if (a) {
    let z = !1, p = [];
    for (let [b, $] of Object.entries(a))
      typeof $ == "boolean" && (z = !0), $ === !0 && p.push(b);
    z && (y["data-headlessui-state"] = p.join(" "));
  }
  if (m === "template") {
    if (g = xe(g ?? []), Object.keys(h).length > 0 || Object.keys(o).length > 0) {
      let [z, ...p] = g ?? [];
      if (!Xt(z) || p.length > 0)
        throw new Error(['Passing props on "template"!', "", `The current component <${n} /> is rendering a "template".`, "However we need to passthrough the following props:", Object.keys(h).concat(Object.keys(o)).map((T) => T.trim()).filter((T, v, u) => u.indexOf(T) === v).sort((T, v) => T.localeCompare(v)).map((T) => `  - ${T}`).join(`
`), "", "You can apply a few solutions:", ['Add an `as="..."` prop, to ensure that we render an actual element instead of a "template".', "Render a single element as the child so that we can forward the props onto that element."].map((T) => `  - ${T}`).join(`
`)].join(`
`));
      let b = we((i = z.props) != null ? i : {}, h), $ = Ee(z, b);
      for (let T in b)
        T.startsWith("on") && ($.props || ($.props = {}), $.props[T] = b[T]);
      return $;
    }
    return Array.isArray(g) && g.length === 1 ? g[0] : g;
  }
  return Q(m, Object.assign({}, h, y), { default: () => g });
}
function xe(e) {
  return e.flatMap((o) => o.type === I ? xe(o.children) : [o]);
}
function we(...e) {
  if (e.length === 0)
    return {};
  if (e.length === 1)
    return e[0];
  let o = {}, t = {};
  for (let a of e)
    for (let n in a)
      n.startsWith("on") && typeof a[n] == "function" ? (t[n] != null || (t[n] = []), t[n].push(a[n])) : o[n] = a[n];
  if (o.disabled || o["aria-disabled"])
    return Object.assign(o, Object.fromEntries(Object.keys(t).map((a) => [a, void 0])));
  for (let a in t)
    Object.assign(o, { [a](n, ...r) {
      let i = t[a];
      for (let m of i) {
        if (n instanceof Event && n.defaultPrevented)
          return;
        m(n, ...r);
      }
    } });
  return o;
}
function Jt(e) {
  let o = Object.assign({}, e);
  for (let t in o)
    o[t] === void 0 && delete o[t];
  return o;
}
function $e(e, o = []) {
  let t = Object.assign({}, e);
  for (let a of o)
    a in t && delete t[a];
  return t;
}
function Xt(e) {
  return e == null ? !1 : typeof e.type == "string" || typeof e.type == "object" || typeof e.type == "function";
}
let en = 0;
function tn() {
  return ++en;
}
function Y() {
  return tn();
}
var G = ((e) => (e.Space = " ", e.Enter = "Enter", e.Escape = "Escape", e.Backspace = "Backspace", e.Delete = "Delete", e.ArrowLeft = "ArrowLeft", e.ArrowUp = "ArrowUp", e.ArrowRight = "ArrowRight", e.ArrowDown = "ArrowDown", e.Home = "Home", e.End = "End", e.PageUp = "PageUp", e.PageDown = "PageDown", e.Tab = "Tab", e))(G || {});
function D(e) {
  var o;
  return e == null || e.value == null ? null : (o = e.value.$el) != null ? o : e.value;
}
function re(e, o) {
  if (e)
    return e;
  let t = o ?? "button";
  if (typeof t == "string" && t.toLowerCase() === "button")
    return "button";
}
function nn(e, o) {
  let t = P(re(e.value.type, e.value.as));
  return V(() => {
    t.value = re(e.value.type, e.value.as);
  }), Ae(() => {
    var a;
    t.value || D(o) && D(o) instanceof HTMLButtonElement && !((a = D(o)) != null && a.hasAttribute("type")) && (t.value = "button");
  }), t;
}
var ze = ((e) => (e[e.None = 1] = "None", e[e.Focusable = 2] = "Focusable", e[e.Hidden = 4] = "Hidden", e))(ze || {});
let on = O({ name: "Hidden", props: { as: { type: [Object, String], default: "div" }, features: { type: Number, default: 1 } }, setup(e, { slots: o, attrs: t }) {
  return () => {
    let { features: a, ...n } = e, r = { "aria-hidden": (a & 2) === 2 ? !0 : void 0, style: { position: "fixed", top: 1, left: 1, width: 1, height: 0, padding: 0, margin: -1, overflow: "hidden", clip: "rect(0, 0, 0, 0)", whiteSpace: "nowrap", borderWidth: "0", ...(a & 4) === 4 && (a & 2) !== 2 && { display: "none" } } };
    return U({ ourProps: r, theirProps: n, slot: {}, attrs: t, slots: o, name: "Hidden" });
  };
} });
function an(e) {
  var o;
  let t = (o = e?.form) != null ? o : e.closest("form");
  if (t) {
    for (let a of t.elements)
      if (a.tagName === "INPUT" && a.type === "submit" || a.tagName === "BUTTON" && a.type === "submit" || a.nodeName === "INPUT" && a.type === "image") {
        a.click();
        return;
      }
  }
}
function ln(e, o, t) {
  let a = P(t?.value), n = S(() => e.value !== void 0);
  return [S(() => n.value ? e.value : a.value), function(r) {
    return n.value || (a.value = r), o?.(r);
  }];
}
let Te = Symbol("DescriptionContext");
function rn() {
  let e = L(Te, null);
  if (e === null)
    throw new Error("Missing parent");
  return e;
}
function sn({ slot: e = P({}), name: o = "Description", props: t = {} } = {}) {
  let a = P([]);
  function n(r) {
    return a.value.push(r), () => {
      let i = a.value.indexOf(r);
      i !== -1 && a.value.splice(i, 1);
    };
  }
  return K(Te, { register: n, slot: e, name: o, props: t }), S(() => a.value.length > 0 ? a.value.join(" ") : void 0);
}
O({ name: "Description", props: { as: { type: [Object, String], default: "p" }, id: { type: String, default: () => `headlessui-description-${Y()}` } }, setup(e, { attrs: o, slots: t }) {
  let a = rn();
  return V(() => ue(a.register(e.id))), () => {
    let { name: n = "Description", slot: r = P({}), props: i = {} } = a, { id: m, ...h } = e, g = { ...Object.entries(i).reduce((y, [z, p]) => Object.assign(y, { [z]: j(p) }), {}), id: m };
    return U({ ourProps: g, theirProps: h, slot: r.value, attrs: o, slots: t, name: n });
  };
} });
let Ce = Symbol("LabelContext");
function Pe() {
  let e = L(Ce, null);
  if (e === null) {
    let o = new Error("You used a <Label /> component, but it is not inside a parent.");
    throw Error.captureStackTrace && Error.captureStackTrace(o, Pe), o;
  }
  return e;
}
function dn({ slot: e = {}, name: o = "Label", props: t = {} } = {}) {
  let a = P([]);
  function n(r) {
    return a.value.push(r), () => {
      let i = a.value.indexOf(r);
      i !== -1 && a.value.splice(i, 1);
    };
  }
  return K(Ce, { register: n, slot: e, name: o, props: t }), S(() => a.value.length > 0 ? a.value.join(" ") : void 0);
}
O({ name: "Label", props: { as: { type: [Object, String], default: "label" }, passive: { type: [Boolean], default: !1 }, id: { type: String, default: () => `headlessui-label-${Y()}` } }, setup(e, { slots: o, attrs: t }) {
  let a = Pe();
  return V(() => ue(a.register(e.id))), () => {
    let { name: n = "Label", slot: r = {}, props: i = {} } = a, { id: m, passive: h, ...g } = e, y = { ...Object.entries(i).reduce((z, [p, b]) => Object.assign(z, { [p]: j(b) }), {}), id: m };
    return h && (delete y.onClick, delete y.htmlFor, delete g.onClick), U({ ourProps: y, theirProps: g, slot: r, attrs: t, slots: o, name: n });
  };
} });
let Be = Symbol("GroupContext");
O({ name: "SwitchGroup", props: { as: { type: [Object, String], default: "template" } }, setup(e, { slots: o, attrs: t }) {
  let a = P(null), n = dn({ name: "SwitchLabel", props: { htmlFor: S(() => {
    var i;
    return (i = a.value) == null ? void 0 : i.id;
  }), onClick(i) {
    a.value && (i.currentTarget.tagName === "LABEL" && i.preventDefault(), a.value.click(), a.value.focus({ preventScroll: !0 }));
  } } }), r = sn({ name: "SwitchDescription" });
  return K(Be, { switchRef: a, labelledby: n, describedby: r }), () => U({ theirProps: e, ourProps: {}, slot: {}, slots: o, attrs: t, name: "SwitchGroup" });
} });
let un = O({ name: "Switch", emits: { "update:modelValue": (e) => !0 }, props: { as: { type: [Object, String], default: "button" }, modelValue: { type: Boolean, default: void 0 }, defaultChecked: { type: Boolean, optional: !0 }, name: { type: String, optional: !0 }, value: { type: String, optional: !0 }, id: { type: String, default: () => `headlessui-switch-${Y()}` } }, inheritAttrs: !1, setup(e, { emit: o, attrs: t, slots: a, expose: n }) {
  let r = L(Be, null), [i, m] = ln(S(() => e.modelValue), (v) => o("update:modelValue", v), S(() => e.defaultChecked));
  function h() {
    m(!i.value);
  }
  let g = P(null), y = r === null ? g : r.switchRef, z = nn(S(() => ({ as: e.as, type: t.type })), y);
  n({ el: y, $el: y });
  function p(v) {
    v.preventDefault(), h();
  }
  function b(v) {
    v.key === G.Space ? (v.preventDefault(), h()) : v.key === G.Enter && an(v.currentTarget);
  }
  function $(v) {
    v.preventDefault();
  }
  let T = S(() => {
    var v, u;
    return (u = (v = D(y)) == null ? void 0 : v.closest) == null ? void 0 : u.call(v, "form");
  });
  return V(() => {
    ie([T], () => {
      if (!T.value || e.defaultChecked === void 0)
        return;
      function v() {
        m(e.defaultChecked);
      }
      return T.value.addEventListener("reset", v), () => {
        var u;
        (u = T.value) == null || u.removeEventListener("reset", v);
      };
    }, { immediate: !0 });
  }), () => {
    let { id: v, name: u, value: x, ...c } = e, k = { checked: i.value }, B = { id: v, ref: y, role: "switch", type: z.value, tabIndex: 0, "aria-checked": i.value, "aria-labelledby": r?.labelledby.value, "aria-describedby": r?.describedby.value, onClick: p, onKeyup: b, onKeypress: $ };
    return Q(I, [u != null && i.value != null ? Q(on, Jt({ features: ze.Hidden, as: "input", type: "checkbox", hidden: !0, readOnly: !0, checked: i.value, name: u, value: x })) : null, U({ ourProps: B, theirProps: { ...t, ...$e(c, ["modelValue", "defaultChecked"]) }, slot: k, attrs: t, slots: a, name: "Switch" })]);
  };
} });
const cn = /* @__PURE__ */ l("span", {
  "test-id": "toggle-anchor",
  class: "sr-only"
}, "Use setting", -1), Zn = /* @__PURE__ */ O({
  __name: "Toggle",
  props: {
    checked: { type: Boolean, default: !1 },
    disabled: { type: Boolean, default: !1 },
    themed: { type: Boolean, default: !1 }
  },
  emits: ["toggle"],
  setup(e, { emit: o }) {
    const t = e;
    function a() {
      o("toggle", !t.checked);
    }
    return (n, r) => (s(), E(j(un), {
      "onUpdate:modelValue": a,
      "model-value": t.checked,
      class: C([
        t.checked ? t.themed ? "bg-theme-500 focus:ring-theme-500" : "bg-indigo-600 focus:ring-indigo-500" : "bg-dynamic-neutral-300 focus:ring-indigo-500",
        t.disabled ? "opacity-700" : "",
        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ring-offset-white dark:ring-offset-neutral-800"
      ])
    }, {
      default: M(() => [
        cn,
        l("span", {
          "aria-hidden": "true",
          class: C([
            t.checked ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-dynamic-neutral-light shadow transform ring-0 transition ease-in-out duration-200"
          ])
        }, null, 2)
      ]),
      _: 1
    }, 8, ["model-value", "class"]));
  }
}), pn = /* @__PURE__ */ l("i", { class: "far fa-comments" }, null, -1), fn = { class: "flex flex-row max-w-full p-4 bg-opacity-0 cursor-pointer xs:space-x-3 xs:p-4 bg-neutral-500 pointer-fine:dark:hover:bg-opacity-10 pointer-fine:hover:bg-opacity-5 border-spacing-0" }, mn = { class: "post-content" }, hn = {
  "data-testid": "post-text",
  class: "xs:!mt-6 xs:!mb-4 xs:text-lg md:text-xl text-dynamic-neutral-700 dark:text-dynamic-neutral-300"
}, gn = {
  key: 1,
  class: "relative inline-block scroll-px-4 px-4 space-x-2 overflow-x-scroll h-64 whitespace-nowrap snap-x -left-4 w-[calc(100%+2rem)] align-middle hide-scrollbar"
}, yn = ["src"], bn = ["href"], kn = ["src"], vn = { class: "flex flex-col p-3 space-y-1 text-sm break-all" }, xn = { class: "text-dynamic-neutral-800 dark:text-dynamic-neutral-200" }, wn = /* @__PURE__ */ l("i", { class: "far fa-link" }, null, -1), $n = { class: "font-bold text-dynamic-neutral-800 dark:text-dynamic-neutral-200" }, zn = { class: "hidden text-dynamic-neutral-600 dark:text-dynamic-neutral-400 xs:flex" }, Tn = { class: "text-sm text-dynamic-neutral-500 dark:text-dynamic-neutral-400" }, Fn = /* @__PURE__ */ O({
  __name: "PostExpanded",
  props: {
    post: {},
    user: {},
    bookmarked: { type: Boolean },
    reacted: { type: Boolean },
    photos: {},
    links: {},
    permissions: {}
  },
  emits: [
    "bookmark-tap",
    "reaction-tap",
    "reply-tap",
    "share-tap",
    "post-tap",
    "context-menu-tap",
    "pin-tap",
    "delete-tap",
    "copy-link-tap",
    "report-tap",
    "reply-to-tap",
    "user-tap"
  ],
  setup(e, { emit: o }) {
    const t = e, a = L("auth_id", null), n = L("appConfig", {}), r = P({ x: 0, y: 0 }), i = S(() => ({
      template: t.post.text.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/{/g, "&lcub;").replace(/}/g, "&rcub;").replace(/\[/g, "&lbrack").replace(/\]/g, "&rbrack").replace(/\n/g, "<br/>").replace(/!!([^!]+)!!/g, '<span class="italic">$1</span>').replace(/__([^_]+)__/g, '<span class="underline">$1</span>').replace(/~~([^~]+)~~/g, '<span class="line-through">$1</span>').replace(/\*\*([^*]+)\*\*/g, '<span class="font-bold">$1</span>').replace(
        /``([^`]+)``/g,
        '<span class="border rounded-md py-0.5 px-1 border-theme-500 bg-theme-100 text-theme-500">$1</span>'
      ).replace(
        /(([a-zA-Z0-9]+:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,4})(:[0-9]+)?((\/.{0,14}).*)?)/g,
        '<a href="$1" clas v-on:click.stop rel="noreferrer" target="_blank">$2$4$5...</a>'
        // ''
      ).replace(
        /#([a-zA-Z0-9_]+)/g,
        `<RouterLink v-on:click.stop v-bind:to="{name: 'tag', params: { tagId: '$1' }}" class="font-semibold text-theme-600 dark:text-theme-300 tint-rate-75">#$1</RouterLink>`
      ).replace(
        /@([a-zA-Z0-9_S]+)/g,
        `<RouterLink v-on:click.stop v-bind:to="{name: 'profile', params: { username: '$1'}}" class="font-semibold rounded-lg text-theme-600 dark:text-theme-300 tint-rate-75">@$1</RouterLink>`
      ),
      name: "PostTextContent"
    })), m = P(!1), h = P(null);
    function g() {
      console.log("emitting pin Post event..."), o("pin-tap", t.post.id);
    }
    function y() {
      console.log("emitting delete Post event..."), o("delete-tap", t.post.id);
    }
    function z() {
      console.log("emitting report Post event..."), o("report-tap", t.post.id);
    }
    function p() {
      console.log("emitting copy link event..."), o("copy-link-tap", t.post.id), navigator.clipboard && navigator.clipboard.writeText(
        window.location.origin + "/post/" + t.post.id
      );
    }
    function b(u) {
      o("reply-to-tap", u.id);
    }
    async function $() {
      o("share-tap", t.post.id);
      const u = {
        title: `Post by ${t.user?.name}`,
        text: t.post.text,
        url: window.location.origin + "/post/" + t.post.id
      };
      try {
        await window.navigator.share(u);
      } catch (x) {
        console.log(x);
      }
    }
    function T() {
      m.value = !m.value;
    }
    ye(h.value, () => console.log("CLikc outside"));
    const v = S(() => ({
      lists: [
        {
          items: [
            {
              label: "Pin",
              icon: "fas fa-thumbtack",
              action: g,
              show: a === t.post.user_id
            },
            {
              label: "Link",
              icon: "fas fa-link",
              action: p,
              show: !0
            },
            {
              label: "Share via...",
              icon: "fas fa-share-alt",
              action: $,
              show: "share" in navigator
            },
            {
              label: "Delete",
              icon: "fas fa-trash",
              action: y,
              show: a === t.post.user_id
            },
            {
              label: "Report",
              icon: "fas fa-flag",
              action: z,
              show: !0
            }
          ]
        },
        {
          items: [
            {
              label: "Cancel",
              icon: "fas fa-times",
              show: !0,
              action: () => console.log(`cancel link ${t.post.id}`)
            }
          ]
        }
      ]
    }));
    return (u, x) => (s(), d(I, null, [
      u.post.reply_to_id !== null && u.post.reply_to ? (s(), d("a", {
        key: 0,
        onClick: x[0] || (x[0] = A((c) => b(u.post.reply_to), ["stop"])),
        class: "relative flex items-center px-6 py-4 space-x-1 text-xs font-medium leading-relaxed border-b cursor-pointer text-dynamic-neutral-400 hover:text-dynamic-neutral-500 dark:text-dynamic-neutral-400 dark:hover:text-dynamic-neutral-300 border-dynamic-neutral-100 dark:border-dynamic-neutral-800"
      }, [
        pn,
        l("span", null, "Replying to @" + w(u.post.reply_to.user?.username), 1)
      ])) : f("", !0),
      l("a", {
        onClick: x[9] || (x[9] = A((c) => o("post-tap"), ["stop"])),
        class: "relative post-element"
      }, [
        (s(), E(de, { to: "body" }, [
          m.value ? (s(), E(ce, {
            key: 0,
            "horizontal-position": r.value.x,
            "vertical-position": r.value.y,
            config: v.value,
            onCloseContextTap: x[1] || (x[1] = (c) => m.value = !1),
            ref_key: "contextElementRef",
            ref: h
          }, null, 8, ["horizontal-position", "vertical-position", "config"])) : f("", !0)
        ])),
        l("div", fn, [
          l("div", mn, [
            u.post && u.user ? (s(), E(ke, {
              key: 0,
              user: u.user,
              "readable-date": u.post.created_at_readable ?? "",
              network: u.post.network || void 0,
              bookmarked: !!u.bookmarked,
              withProfile: !0,
              onBookmarkTap: x[2] || (x[2] = () => o("bookmark-tap", u.post.id)),
              onContextMenuTap: x[3] || (x[3] = (c) => {
                r.value.x = c.left, r.value.y = c.top, T();
              }),
              onUserTap: x[4] || (x[4] = () => o("user-tap"))
            }, null, 8, ["user", "readable-date", "network", "bookmarked"])) : f("", !0),
            l("p", hn, [
              (s(), E(N(i.value)))
            ]),
            u.post.photos?.length ? (s(), d("div", gn, [
              (s(!0), d(I, null, _(u.post.photos || [], (c, k) => (s(), d("div", {
                key: k,
                class: "box-border relative inline-block w-auto h-full overflow-hidden align-middle rounded-lg bg-dynamic-neutral-500/20 snap-start"
              }, [
                l("img", {
                  class: "inline-flex object-cover object-center w-auto h-full max-h-full",
                  src: c ? j(n).content_url + "posts/large/" + c.filename : "",
                  alt: ""
                }, null, 8, yn)
              ]))), 128))
            ])) : f("", !0),
            u.links && u.links.length ? (s(), d("a", {
              key: 2,
              href: u.links[0].url,
              target: "_blank",
              rel: "noreferrer",
              onClick: x[5] || (x[5] = A(() => {
              }, ["stop"])),
              class: "flex flex-col overflow-hidden bg-opacity-0 border rounded-md sm:flex-row md:flex-col lg:flex-row border-dynamic-neutral-200 dark:border-dynamic-neutral-700 bg-nuetral-500 hover:bg-opacity-10"
            }, [
              u.links[0].image_filename ? (s(), d("img", {
                key: 0,
                src: j(n).content_url && u.links[0].image_filename ? j(n).content_url + "links/hd/" + u.links[0].image_filename : "",
                class: "flex-shrink-0 object-cover sm:w-48 md:w-[unset] lg:w-48",
                loading: "lazy"
              }, null, 8, kn)) : f("", !0),
              l("div", vn, [
                l("p", xn, [
                  wn,
                  Ie("  "),
                  l("span", null, w(u.links[0].url.replace(
                    /(([a-zA-Z0-9]+:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,4})(:[0-9]+)?((\/.{0,14}).*)?)/g,
                    "$4"
                  )), 1)
                ]),
                l("p", $n, w(u.links[0].title), 1),
                l("p", zn, w(u.links[0].description), 1)
              ])
            ], 8, bn)) : f("", !0),
            l("div", Tn, " Posted " + w(u.post.created_at_readable), 1),
            H(pe, {
              reacted: !!u.reacted,
              bookmarked: !!u.bookmarked,
              onBookmarkTap: x[6] || (x[6] = () => o("bookmark-tap", u.post.id)),
              onReactionTap: x[7] || (x[7] = () => o("reaction-tap", u.post.id)),
              onReplyTap: x[8] || (x[8] = () => o("reply-tap", u.post.id)),
              onShareTap: $,
              post: u.post
            }, null, 8, ["reacted", "bookmarked", "post"])
          ])
        ])
      ])
    ], 64));
  }
}), Cn = { class: "relative w-full max-w-sm overflow-hidden rounded-md shadow-md bg-dynamic-neutral-light dark:bg-dynamic-neutral-900" }, Pn = { class: "w-full h-full" }, Bn = { class: "px-4 py-10 sm:px-10" }, On = ["action"], Sn = ["value"], jn = /* @__PURE__ */ l("label", {
  for: "email",
  class: "block text-sm font-medium leading-5 text-dynamic-neutral-700 dark:text-dynamic-neutral-500"
}, "Email address", -1), En = { class: "mt-1 rounded-md shadow-sm" }, An = { class: "mt-6" }, In = /* @__PURE__ */ l("label", {
  for: "password",
  class: "block text-sm font-medium leading-5 text-dynamic-neutral-700 dark:text-dynamic-neutral-500"
}, "Password", -1), Ln = { class: "mt-1 rounded-md shadow-sm" }, Hn = { class: "flex items-center justify-between mt-6" }, Rn = { class: "flex items-center" }, _n = /* @__PURE__ */ l("label", {
  for: "remember_me",
  class: "block ml-2 text-sm leading-5 text-dynamic-neutral-900 dark:text-dynamic-neutral-300"
}, "Remember me", -1), Un = { class: "mt-6 allaxis-indigo-theme" }, Dn = /* @__PURE__ */ Re('<div class="flex"><div class="flex flex-grow h-2 bg-allaxis-indigo"></div><div class="flex flex-grow h-2 bg-allaxis-cyan"></div><div class="flex flex-grow h-2 bg-allaxis-lime"></div><div class="flex flex-grow h-2 bg-allaxis-yellow"></div></div>', 1), Wn = /* @__PURE__ */ O({
  __name: "AuthenticationForm",
  props: {
    formActionUrl: {},
    isLoggingIn: { type: Boolean },
    csrfToken: {}
  },
  emits: ["login"],
  setup(e, { emit: o }) {
    const t = e, a = P(""), n = P(""), r = P(!1);
    function i() {
      a.value = "", n.value = "", r.value = !1;
    }
    function m(h) {
      t.formActionUrl || (h.preventDefault(), o(
        "login",
        {
          userInput: a.value,
          passwordInput: n.value,
          rememberMeInput: r.value
        },
        function() {
          i();
        }
      ));
    }
    return (h, g) => (s(), d("div", Cn, [
      l("div", Pn, [
        l("div", Bn, [
          l("form", {
            method: "POST",
            action: t.formActionUrl,
            onSubmit: m
          }, [
            t.csrfToken ? (s(), d("input", {
              key: 0,
              type: "hidden",
              name: "_token",
              value: t.csrfToken,
              autocomplete: "off"
            }, null, 8, Sn)) : f("", !0),
            l("div", null, [
              jn,
              l("div", En, [
                H(j(J), {
                  id: "email",
                  name: "email",
                  modelValue: a.value,
                  "onUpdate:modelValue": g[0] || (g[0] = (y) => a.value = y),
                  placeholder: "Email",
                  "leading-icon": "fa-user",
                  required: ""
                }, null, 8, ["modelValue"])
              ])
            ]),
            l("div", An, [
              In,
              l("div", Ln, [
                H(j(J), {
                  id: "password",
                  name: "password",
                  modelValue: n.value,
                  "onUpdate:modelValue": g[1] || (g[1] = (y) => n.value = y),
                  placeholder: "Password",
                  "leading-icon": "fa-lock",
                  type: "password",
                  required: ""
                }, null, 8, ["modelValue"])
              ])
            ]),
            l("div", Hn, [
              l("div", Rn, [
                Le(l("input", {
                  id: "remember_me",
                  type: "checkbox",
                  name: "remember",
                  class: "w-4 h-4 text-blue-600 transition duration-150 ease-in-out form-checkbox",
                  "onUpdate:modelValue": g[2] || (g[2] = (y) => r.value = y)
                }, null, 512), [
                  [He, r.value]
                ]),
                _n
              ])
            ]),
            l("div", Un, [
              H(j(Ve), {
                text: "Login",
                "loading-text": "Logging in...",
                loading: t.isLoggingIn,
                themed: "",
                centered: "",
                "is-submit": "",
                type: "primary",
                class: "w-full text-center"
              }, null, 8, ["loading"])
            ])
          ], 40, On)
        ]),
        Dn
      ])
    ]));
  }
});
export {
  Wn as AuthenticationForm,
  J as BaseInput,
  Ve as Button,
  Nn as ButtonGroup,
  Qe as Card,
  Mn as CenteredContainer,
  ce as ContextMenu,
  pe as PostActionSection,
  Fn as PostExpanded,
  Vn as PostFeedItem,
  ke as PostHeader,
  be as ProfilePhoto,
  Zn as Toggle
};
