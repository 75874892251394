import { defineStore, acceptHMRUpdate } from "pinia";
import { ref } from "vue";

import usePostStore from "@/stores/resources/posts-store";

import type { InitialRequestData } from "@/services/app-service";
import {
  addPostBookmark,
  getAllBookmarks,
  removePostBookmark,
} from "@/services/bookmark-service";

const useBookmarkStore = defineStore("bookmarks", () => {
  const postBookmarksById = ref<Map<number, number>>(new Map()); // { postId: true }

  function init(data: InitialRequestData) {
    setBookmarks(data.resources.bookmarks);
  }

  function removeBookmarkedPost(postId: number) {
    const localMap = postBookmarksById.value;
    localMap.delete(postId);
    postBookmarksById.value = localMap;
  }

  /**
   * Get all bookmarks.
   */
  async function getBookmarks() {
    const { data: bookmarks } = await getAllBookmarks();
    parseBookmarks(bookmarks);
  }

  /**
   * Add a bookmark to a post.
   * @param postId
   */
  async function bookmarkPost(postId: number) {
    const { data: bookmark } = await addPostBookmark(postId);
    setBookmarks([bookmark]);
  }

  /**
   * Unbookmark a post.
   * @param postId Post id to unbookmark.
   * @param bookmarkId Bookmark id to delete.
   */
  async function unbookmarkPost(postId: number, bookmarkId: number) {
    const { status } = await removePostBookmark(postId, bookmarkId);
    if (status === 204) {
      postBookmarksById.value.delete(postId);
    }
  }

  /**
   * Toggle a bookmark on a post.
   * @param postId Post id to toggle bookmark.
   */
  async function toggleBookmark(postId: number) {
    const postBookmarkId = postBookmarksById.value.get(postId);
    if (postBookmarkId) {
      await unbookmarkPost(postId, postBookmarkId as number);
    } else {
      await bookmarkPost(postId);
    }
  }

  /**
   * Store bookmarks by id in Map.
   * @param bookmarks
   */
  function setBookmarks(bookmarks: Array<Bookmark>) {
    const postsToBeParsed: Array<Post> = [];
    bookmarks.forEach((bookmark) => {
      if (
        bookmark.bookmarkable_type === "App\\Models\\Post" &&
        bookmark.bookmarkable !== null
      ) {
        postBookmarksById.value.set(bookmark.bookmarkable_id, bookmark.id);
        postsToBeParsed.push(bookmark.bookmarkable as Post);
      }
      //TODO: Handle other types of bookmarks here.
    });
  }

  /**
   * Parse bookmarks and their relationships.
   * @param bookmarks
   */
  function parseBookmarks(bookmarks: Array<Bookmark>): Array<Bookmark> {
    const postStore = usePostStore();
    const postsToBeParsed = bookmarks
      .filter(
        (bookmark) =>
          bookmark.bookmarkable_type === "App\\Models\\Post" &&
          bookmark.bookmarkable !== null
      )
      .map((bookmark) => bookmark.bookmarkable as Post);
    postStore.parsePosts(postsToBeParsed);
    setBookmarks(bookmarks);
    return bookmarks;
  }

  return {
    postBookmarksById,
    parseBookmarks,
    getBookmarks,
    bookmarkPost,
    toggleBookmark,
    removeBookmarkedPost,
    init,
  };
});

export default useBookmarkStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBookmarkStore, import.meta.hot));
}
