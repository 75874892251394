<script lang="ts" setup>
import { Card, BaseInput } from "@allaxis/ui";
import { ref } from "vue";

import { ROUTES } from "@/router";
import api from "@/services/api";

const timer = ref<undefined | number>();

const userSearchItems = ref([]);
const networkSearchItems = ref([]);

async function handleSearch(query: string) {
  const {
    data: { users, networks },
  } = await api.get(`/search?query=${query}`);

  userSearchItems.value = users;
  networkSearchItems.value = networks;
}

function handleKeyPress(e: Event) {
  if (timer.value) window.clearTimeout(timer.value);

  timer.value = window.setTimeout(
    () => handleSearch((e.target as HTMLInputElement).value as string),
    1000
  );
}

function clearResults() {
  setTimeout(() => {
    userSearchItems.value = [];
    networkSearchItems.value = [];
  }, 500);
}
</script>
<template>
  <section id="search-component" class="relative">
    <BaseInput
      id="search-input"
      placeholder="Search"
      class="bg-tint-opacity-50"
      leading-icon="fa-search"
      has-tint-opacity
      @keypress="handleKeyPress"
      @blur="clearResults"
      full-width
    />

    <Card
      v-if="userSearchItems.length || networkSearchItems.length"
      :padded="false"
      class="absolute w-full h-auto border !shadow-xl dark:!shadow-black/40 border-neutral-100 dark:border-neutral-800 bg-tint-opacity-100 top-11"
    >
      <section v-if="userSearchItems.length" class="p-2">
        <template
          v-for="(item, i) in (userSearchItems as Array<User>)"
          :key="i"
        >
          <RouterLink
            :to="{ name: ROUTES.PROFILE, params: { username: item.username } }"
            custom
            v-slot="{ navigate }"
          >
            <button
              @click="() => navigate()"
              class="flex flex-col w-full p-4 space-y-2 text-sm rounded-md hover:bg-dynamic-neutral-100 dark:hover:bg-dynamic-neutral-800"
            >
              <p class="text-dynamic-neutral-700 dark:text-dynamic-neutral-200">
                {{ item.name }}
              </p>
              <p
                class="font-medium text-dynamic-neutral-500 dark:text-dynamic-neutral-400"
              >
                @{{ item.username }}
              </p>
            </button>
          </RouterLink>
        </template>
      </section>

      <section v-if="networkSearchItems.length" class="p-2">
        <template
          v-for="(item, i) in (networkSearchItems as Array<Network>)"
          :key="i"
        >
          <RouterLink
            :to="{ name: ROUTES.NETWORK, params: { slug: item.slug } }"
            custom
            v-slot="{ navigate }"
          >
            <button
              @click="navigate"
              class="flex flex-col w-full p-4 space-y-2 text-sm rounded-md cursor-pointer hover:bg-dynamic-neutral-100 dark:hover:bg-dynamic-neutral-800"
            >
              <p class="text-dynamic-neutral-700 dark:text-dynamic-neutral-200">
                {{ item.title }}
              </p>
              <p
                class="font-medium text-dynamic-neutral-500 dark:text-dynamic-neutral-400"
              >
                {{ item.slug }}
              </p>
            </button>
          </RouterLink>
        </template>
      </section>
    </Card>
  </section>
</template>
