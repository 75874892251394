<script lang="ts" setup>
import NotificationCounter from "./NotificationCounter.vue";

import { ROUTES } from "@/router";

defineProps<PropsType>();

function getTypeOfNotificationCountForRouteName(name: string) {
  if (name == ROUTES.MESSAGES) return "UNREAD_MESSAGES";
  if (name == ROUTES.NOTIFICATIONS) return "UNREAD_MENTIONS_AND_REACTIONS";
  return "NONE";
}

type PropsType = {
  tabs: {
    icon: string;
    route: { name: string };
  }[];
};
</script>

<template>
  <nav id="mobile-nav-landscape">
    <div
      class="flex z-[70] flex-col-reverse w-full max-w-screen-md p-2 space-y-2 space-y-reverse py-2-safe items-between md:max-w-full text-dynamic-neutral-500 dark:text-dynamic-neutral-400 mr-0-safe"
    >
      <RouterLink
        v-for="(tab, index) in tabs"
        :to="tab.route"
        :key="index"
        exact-active-class="text-dynamic-neutral-700 pointer-fine:focus:text-dynamic-neutral-700 dark:pointer-fine:focus:text-dynamic-neutral-700 dark:text-dynamic-neutral-100 pointer-fine:hover:text-dynamic-neutral-700 dark:pointer-fine:hover:text-dynamic-neutral-200 dark:pointer-fine:hover:bg-dynamic-neutral-500/20 bg-dynamic-neutral-500/5"
        class="flex items-center justify-center flex-grow p-1 text-center rounded-md cursor-pointer xs:p-3 pointer-fine:hover:bg-dynamic-neutral-100 dark:pointer-fine:hover:bg-dynamic-neutral-800 pointer-fine:focus:bg-dynamic-neutral-100 dark:pointer-fine:focus:bg-dynamic-neutral-800 pointer-fine:hover:text-dynamic-neutral-900 dark:pointer-fine:hover:text-dynamic-neutral-200"
      >
        <span class="relative leading-none text-normal xs:text-xl far">
          <NotificationCounter
            is-always-floating
            :type="getTypeOfNotificationCountForRouteName(tab.route.name)"
          />
          <i class="text-normal far" :class="tab.icon"></i>
        </span>
      </RouterLink>
    </div>
  </nav>
</template>
