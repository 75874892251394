import { reactive, ref, toRefs } from "vue";

const uiNotificationState = reactive<UiNotificationStateType>({
  notifications: new Set(),
});

export default function useUiNotifications() {
  const timeoutSet = ref(new Set());
  const notificationTimeoutIdMap = ref<Map<number, NodeJS.Timeout>>(new Map());

  function addNotification(notification: UiNotificationType) {
    const ttl = notification.duration;
    const timestamp = new Date().valueOf();

    notification.data = {
      timestamp,
    };

    if (
      notification.primaryButtonHandler &&
      !notification.secondaryButtonHandler
    ) {
      const cb = notification.primaryButtonHandler;
      notification.primaryButtonHandler = function () {
        cb();
        removeNotification(notification);
      };
    }

    uiNotificationState.notifications.add(notification);

    console.log({
      notification,
    });

    const timeoutId = setTimeout(function () {
      uiNotificationState.notifications.delete(notification);
    }, ttl);

    timeoutSet.value.add(timeoutId);
    notificationTimeoutIdMap.value.set(timestamp, timeoutId);
  }

  function removeNotification(notification: UiNotificationType) {
    clearTimeout(
      notificationTimeoutIdMap.value.get(
        (notification.data as Record<string, number>).timestamp as number
      )
    );
    uiNotificationState.notifications.delete(notification);
  }

  function notify({
    title = "",
    text = "",
    group = "app-notifications",
    type = "default",
    photoUrl,
    duration = 3600,
    icon,
    primaryButtonHandler,
    secondaryButtonHandler,
    primaryButtonText,
    secondaryButtonText,
  }: UiNotificationType) {
    addNotification({
      title,
      text,
      group,
      duration,
      photoUrl,
      type,
      primaryButtonHandler,
      secondaryButtonHandler,
      secondaryButtonText,
      primaryButtonText,
      icon,
      data: {
        type,
        icon,
      },
    });
  }

  return {
    notify,
    dismiss: removeNotification,
    ...toRefs(uiNotificationState),
  };
}

export type UiNotificationType = {
  title: string;
  text?: string;
  duration?: number;
  icon?: string;
  type?: "warn" | "info" | "success" | "error" | "default";
  photoUrl?: string;
  group?: string;
  data?: Record<string | number, unknown>;
  primaryButtonHandler?: (dismissCallback?: () => void) => void;
  primaryButtonText?: string;
  secondaryButtonHandler?: (dismissCallback?: () => void) => void;
  secondaryButtonText?: string;
};

type UiNotificationStateType = {
  notifications: Set<UiNotificationType>;
};
