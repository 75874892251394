<script lang="ts" setup>
import NotificationCounter from "./NotificationCounter.vue";

import { ROUTES } from "@/router";

const props = defineProps<PropsType>();

function getTypeOfNotificationCountForRouteName(name: string) {
  if (name == ROUTES.MESSAGES) return "UNREAD_MESSAGES";
  if (name == ROUTES.NOTIFICATIONS) return "UNREAD_MENTIONS_AND_REACTIONS";
  return "NONE";
}

type PropsType = {
  tabs: {
    icon: string;
    route: { name: string };
  }[];
};
</script>

<template>
  <nav id="mobile-nav-portrait">
    <div
      class="flex z-[70] justify-between w-full max-w-screen-md p-2 space-x-2 md:max-w-full text-dynamic-neutral-500 dark:text-dynamic-neutral-400"
    >
      <RouterLink
        v-for="(tab, index) in props.tabs"
        :to="tab.route"
        :key="index"
        exact-active-class="font-bold text-dynamic-neutral-800 pointer-fine:focus:text-dynamic-neutral-500 dark:pointer-fine:focus:text-nuetral-200 dark:text-dynamic-neutral-100 pointer-fine:hover:text-dynamic-neutral-500 dark:pointer-fine:hover:text-dynamic-neutral-200 dark:pointer-fine:hover:bg-dynamic-neutral-500/20 bg-dynamic-neutral-500/5"
        class="relative flex-grow p-1 text-center rounded-md cursor-pointer xs:p-3 pointer-fine:hover:bg-dynamic-neutral-100 dark:pointer-fine:hover:bg-dynamic-neutral-800 pointer-fine:focus:bg-dynamic-neutral-100 dark:pointer-fine:focus:bg-dynamic-neutral-800 pointer-fine:hover:text-dynamic-neutral-900 dark:pointer-fine:hover:text-dynamic-neutral-200"
      >
        <span class="relative leading-none text-normal xs:text-xl far">
          <NotificationCounter
            is-always-floating
            :type="getTypeOfNotificationCountForRouteName(tab.route.name)"
          />
          <i class="text-normal far" :class="tab.icon"></i>
        </span>
      </RouterLink>
    </div>
    <div class="h-0-safe-bottom"></div>
  </nav>
</template>
