import api from "@/services/api";
import type { NetworkCreationParams } from "@/utils/requestParamBuilders";

export function fetchNetworkBySlug(slug: string) {
  return api.get<{ network: Network }>(`/networks/${slug}/`);
}

export function createNetwork(params: NetworkCreationParams) {
  console.log({
    params,
  });
  return api.post<{ network: Network }>(`/networks/`, {
    ...params,
  });
}

export function getUserNetworks() {
  return api.get<{ networks: Array<Network>; ownedNetworks: Array<Network> }>(
    "networks"
  );
}
