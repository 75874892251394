import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";

import type { InitialRequestData } from "@/services/app-service";
import { createNetwork as create } from "@/services/network-service";
import callOptional from "@/utils/callOptional";
import type { NetworkCreationParams } from "@/utils/requestParamBuilders";

export type NetworkCreationOptions = NetworkCreationParams & {
  onSuccess?: (...args: (Network | unknown)[]) => void;
  onError?: () => void;
};

const useNetworkStore = defineStore("networks", function () {
  const networksBySlug = ref<Map<string, Network>>(new Map());
  const networkSlugById = ref<Map<number, string>>(new Map());

  function init(data: InitialRequestData) {
    setNetworks(data.resources.networks);
  }

  async function createNetwork(options: NetworkCreationOptions) {
    const { onSuccess, onError, ...params } = options;
    try {
      const { data } = await create(params);
      setNetworks([data.network]);
      callOptional(onSuccess, data.network);
      return data.network;
    } catch (e) {
      callOptional(onError);
      console.log("Something went wrong while creating new network");
    }
  }

  function setNetworks(networks: Array<Network>) {
    const networksMap = new Map();
    const slugByIdMap = new Map();

    networks.forEach((n) => {
      networksMap.set(n.slug, n);
      slugByIdMap.set(n.id, n.slug);
    });

    networksBySlug.value = new Map([...networksBySlug.value, ...networksMap]);
    networkSlugById.value = new Map([...networkSlugById.value, ...slugByIdMap]);
  }

  function getBySlug(slug: string) {
    // TODO: fetch network by slug.
  }

  function parseNetworks(networks: Array<Network>) {
    // TODO: Parse other data...
    setNetworks(networks);
  }

  return {
    getBySlug,
    networkSlugById,
    networksBySlug,
    parseNetworks,
    createNetwork,
    init,
  };
});

export default useNetworkStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNetworkStore, import.meta.hot));
}
