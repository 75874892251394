<script setup lang="ts">
import { ref } from "vue";

import useAuthStore from "@/stores/common/auth";

import useVaporUpload from "@/hooks/useVaporUpload";

import PostComposeForm from "./PostComposeForm.vue";

import type { PostPayloadType } from "@/types/appTypes";

const props = withDefaults(defineProps<PropTypes>(), {
  useLargeTextarea: false,
});

const emit = defineEmits<EventTypes>();

const isPublishing = ref(false);
const authStore = useAuthStore();

const { upload } = useVaporUpload();

async function handlePublishPost(
  postList: Array<PostType>,
  onComplete: () => void
) {
  isPublishing.value = true;

  const processedPosts = await Promise.all(
    postList.map(async (post) => {
      const s3PhotoObjects = await Promise.all(
        post.photos.map(async (photo) => {
          const response = await upload(photo);
          return {
            uuid: response.uuid,
            key: response.key,
            content_type: photo.name,
            content: photo.name,
            bucket: response.bucket,
            url: response.url,
          };
        })
      );

      return {
        text: post.text,
        photos: s3PhotoObjects,
      };
    })
  );

  emit("publish", processedPosts, () => {
    onComplete();
    isPublishing.value = false;
  });
}

type PropTypes = {
  useLargeTextarea?: boolean;
};

type PostType = {
  text: string;
  photos: Array<File>;
  photoUrls: Array<string>;
};

type EventTypes = {
  (e: "publish", payload: Array<PostPayloadType>, onComplete: () => void): void;
};
</script>

<template>
  <PostComposeForm
    :use-large-textarea="props.useLargeTextarea"
    :me="authStore.user"
    @publish="handlePublishPost"
  />
</template>
