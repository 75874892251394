<script setup lang="ts">
import useAsset from "@/hooks/useAsset";

const props = withDefaults(defineProps<PropTypes>(), {
  photos: () => [],
});

const asset = useAsset();

type PropTypes = {
  photos: Array<Photo>;
};
</script>
<template>
  <div
    class="relative flex flex-row w-full max-w-xs"
    :class="{
      'grid grid-cols-2 gap-1': props.photos.length > 1,
      'grid-rows-2': props.photos.length > 2,
    }"
  >
    <div
      v-for="(photo, index) in props.photos.slice(0, 4)"
      class="max-w-xs overflow-hidden rounded-md"
      :class="{
        '!max-h-[auto] !w-full max-w-full': props.photos.length == 1,
        'max-h-96': props.photos.length < 3,
        'max-h-48': props.photos.length > 2,
        'col-span-2 w-full !max-w-full':
          index + 1 === props.photos.length && photos.length === 3,
      }"
      :key="index"
    >
      <img
        class="object-cover object-top w-full h-full max-w-full max-h-full"
        :src="asset('messages/hd/' + photo.filename, !!photo.filename)"
      />
    </div>
  </div>
</template>

<style lang="postcss">
.photo-stack {
  & div:nth-child(1) {
    z-index: 20;
  }

  & div:nth-child(2) {
    transform: rotate3d(0, 0, 1, 3deg);
  }

  & div:nth-child(3) {
    transform: rotate3d(0, 0, 1, -3deg);
  }

  & div:nth-child(4) {
    transform: rotate3d(0, 0, 1, 2deg);
  }
}
</style>
