import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, ref } from "vue";

import useUserSuggestionStore from "@/stores/common/user-suggestion-store";
import useBookmarkStore from "@/stores/resources/bookmarks-store";
import useNotificationStore from "@/stores/resources/notifications-store";
import usePostStore from "@/stores/resources/posts-store";
import useReactionsStore from "@/stores/resources/reactions-store";
import useUserStore from "@/stores/resources/users-store";

import usePageState from "@/hooks/usePageState";
import usePostActions from "@/hooks/usePostActions";
import useUiNotifications from "@/hooks/useUiNotifications";

import type { InitialRequestData } from "@/services/app-service";
import { loadHomePageData } from "@/services/page-service";
import type { PostPayloadType } from "@/types/appTypes";
import callOptional from "@/utils/callOptional";
import { sortIterable } from "@/utils/sorting";

type LoadHomeDataOptions = {
  beforePostId?: number;
  afterPostId?: number;
  orderBy?: "time" | "popularity";
};

const useHomePageStore = defineStore("homePage", () => {
  const { loading, errorWhileLoading, fetchPageData } = usePageState();
  const {
    toggleBookmark,
    toggleReaction,
    removePost,
    createPost,
    createPostThread,
  } = usePostActions();

  const { notify } = useUiNotifications();

  const postIdsOrderdByTime = ref<Set<number>>(new Set());
  const postIdsOrderdByPopularity = ref<Set<number>>(new Set());

  const postsStore = usePostStore();
  const reactionStore = useReactionsStore();
  const bookmarkStore = useBookmarkStore();
  const notificationStore = useNotificationStore();
  const userStore = useUserStore();
  const userSuggestionStore = useUserSuggestionStore();

  const shouldLoadMore = ref(true);

  const userSuggestions = computed(() => {
    return [...userSuggestionStore.userSuggestionIds].map(
      (id) => userStore.usersById[id]
    );
  });

  const homePagePosts = computed(() => {
    return [...postIdsOrderdByTime.value]
      .map((id) => {
        const post = postsStore.postsById[id];
        return post;
      })
      .map((post) => {
        const user = userStore.usersById[post.user_id];
        if (!user) return null;
        return {
          ...post,
          user,
          links: Array.from(postsStore.linkIdsByPostId.get(post.id) || [])
            .map((id) => postsStore.linksById.get(id))
            .filter((link) => link !== undefined) as Link[],
          isBookmarked: !!bookmarkStore.postBookmarksById.get(post.id),
          hasReacted: !!reactionStore.userRectionsByPostId.has(post.id),
        };
      })
      .filter((post) => post !== null);
  });

  function pushPostIds(posts: Array<Post>) {
    const oldPostIds = postIdsOrderdByTime.value;

    const sortedPostIds = sortIterable(
      [
        ...Array.from(oldPostIds).map((id) => postsStore.postsById[id]),
        ...posts,
      ],
      "desc",
      "created_at"
    ).map((p) => p.id);

    postIdsOrderdByTime.value = new Set(sortedPostIds);
  }

  function init(data: InitialRequestData) {
    postIdsOrderdByTime.value = new Set(data.activities.home.post_ids);
  }

  /**
   * Load home page data.
   */
  async function loadHomeData(options: LoadHomeDataOptions = {}) {
    await fetchPageData({
      async fetch() {
        await new Promise((resolve) => setTimeout(() => resolve(0), 1000));
        const { data } = await loadHomePageData({
          before_post_id: options?.beforePostId,
          after_post_id: options?.afterPostId,
        });

        notificationStore.parseNotificatiosFromRequestData(data);

        reactionStore.parseReactions(data.reactions);
        bookmarkStore.parseBookmarks(data.bookmarks);
        const posts = postsStore.parsePosts(data.posts);
        pushPostIds(posts);
      },
    });
  }

  async function loadMorePosts() {
    if (
      homePagePosts.value.length > 1 &&
      shouldLoadMore.value &&
      !loading.value
    ) {
      const oldPostCount = homePagePosts.value.length;
      const lastPost = homePagePosts.value[oldPostCount - 1];
      await loadHomeData({ beforePostId: (lastPost as Post).id });

      console.log({ postLoaded: homePagePosts.value.length - oldPostCount });

      if (homePagePosts.value.length - oldPostCount < 50)
        shouldLoadMore.value = false;
    }
  }

  async function publishPostToHomeFeed(
    { text, photos }: PostPayloadType,
    onComplete?: (...args: unknown[]) => void
  ) {
    createPost({
      text,
      photos,
      onSuccess(post) {
        pushPostIds([post as Post]);
        callOptional(onComplete, post);
        notify({
          title: "Post submitted successfully!",
          type: "success",
        });
      },

      onError() {
        notify({
          title: "Post submission error",
          text: "Something went wrong while submitting post",
          type: "error",
        });
      },
    });
  }

  function publishPostThreadToHomeFeed(
    {
      posts,
    }: {
      posts: Array<{ text: string; photos?: Array<Record<string, string>> }>;
    },
    onComplete?: (...args: unknown[]) => void
  ) {
    createPostThread({
      posts,
      onSuccess(...result) {
        if (Array.isArray(result)) pushPostIds([result[0]] as Array<Post>);
        callOptional(onComplete, result);
        console.log({
          posts,
        });
        notify({
          title: `Post${posts.length > 1 ? "s" : ""} submitted successfully!`,
          type: "success",
        });
      },
    });
  }

  function removePostFromHomeFeed(
    postId: number,
    onComplete?: (...args: unknown[]) => void
  ) {
    removePost({
      postId,
      onSuccess() {
        postIdsOrderdByTime.value.delete(postId);
        callOptional(onComplete);
      },

      onError() {
        notify({
          title: "Post removal error",
          text: "Something went wrong while removing post",
          type: "error",
        });
      },
    });
  }

  return {
    loadHomeData,
    loadMorePosts,
    homePagePosts,
    userSuggestions,
    createPost: publishPostToHomeFeed,
    createPostThread: publishPostThreadToHomeFeed,
    removePost: removePostFromHomeFeed,
    toggleBookmark,
    toggleReaction,
    shouldLoadMore,
    loading,
    errorWhileLoading,
    postIdsOrderdByTime,
    init,
  };
});

export default useHomePageStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useHomePageStore, import.meta.hot));
}
