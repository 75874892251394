<script lang="ts" setup>
import { useLocalStorage } from "@vueuse/core";
import {
  onBeforeMount,
  onMounted,
  provide,
  ref,
  computed,
  reactive,
} from "vue";
import { useRouter } from "vue-router";

import ContactSearchModal from "@/components/ContactSearchModal.vue";
import MessageComposeModal from "@/components/MessageComposeModal.vue";
import OverlayProvider from "@/components/OverlayProvider.vue";
import SideMenu from "@/components/SideMenu.vue";

import useAppStore from "@/stores/common/app-store";
import useAuthStore from "@/stores/common/auth";
import usePreferences from "@/stores/common/preferences";
import useUiStore from "@/stores/common/ui";
import useNotificationStore from "@/stores/resources/notifications-store";

import type { AppConfigType } from "./types/appTypes";

import { ROUTES } from "@/router";
import api from "@/services/api";

const ui = useUiStore();
const auth = useAuthStore();
const router = useRouter();
const preferenceStore = usePreferences();

const notificationStore = useNotificationStore();

// type AppConfig = {
//   /**
//    * App's URL for user uploaded content.
//    */
//   content_url?: string;
//   client_base_url?: string;
// };

const appConfig = ref<AppConfigType>({});

const appStore = useAppStore();

const debuggingData = reactive<{
  visualViewport?: {
    topOffset: number;
    leftOffset: number;
    height: number;
    calculatedOffset: number;
  };
}>({});

const onboardingComplete = computed(() =>
  useLocalStorage("onboarding-complete", false)
);

const computedAppConfig = computed(() => appConfig);

provide("appConfig", computedAppConfig.value);
provide("auth_id", auth.id);
provide("client_base_url", appConfig.value.client_base_url);

onBeforeMount(() => {
  // appStore.performInitialFetch();
  preferenceStore.setupAppearance();
});

// watch(
//   () => auth.user,
//   (nextVal, prevVal) => {
//     if (!prevVal && nextVal && !appStore.hasLoadedInitialState) {
//       appStore.performInitialFetch();
//     }
//   }
// );

onMounted(async () => {
  if (typeof (window as any).updateUI === "function")
    (window as any).updateUI();
  const { data, status } = await api.get("/variables");

  if (status !== 200) return;

  console.log({
    dataComingIn: data,
  });

  appConfig.value = data;

  // notificationStore.setupPusherBeamsPushNotifications();
  appStore.setupServiceWorkerEvents();
  appStore.setupEventListeners();
  notificationStore.startPushNotifications();
  // notificationStore.startWebSockets();
});
</script>

<template>
  <template
    v-if="
      auth.isAuthenticated &&
      appConfig.content_url != '' &&
      onboardingComplete &&
      appStore.hasLoadedInitialState
    "
  >
    <OverlayProvider>
      <SideMenu
        :user="auth.user"
        :expanded="ui.sidebarOpen"
        @collapse="ui.closeSidebar"
        @logout="auth.sessionEnd"
      />
      <main
        id="main"
        :class="{
          'xl:ml-64 md:ml-20-safe': ui.sidebarOpen,
          'ml-0-safe md:ml-20-safe': !ui.sidebarOpen,
        }"
        class="relative flex flex-col items-center h-full min-h-screen transition-all duration-300 ease-out bg-dynamic-neutral-light aspect-landscape:md:mr-20-safe lg:!mr-0 dark:bg-dynamic-neutral-900 2xl:ml-64"
      >
        <RouterView />
      </main>
    </OverlayProvider>
  </template>

  <template v-else-if="auth.user && !appStore.hasLoadedInitialState">
    <div>App loading</div>
  </template>

  <template v-else>
    <RouterView />
  </template>

  <MessageComposeModal
    v-if="
      auth.isAuthenticated &&
      ui.messageComposeModalOpen &&
      [
        ROUTES.MESSAGE_GROUP_THREAD,
        ROUTES.MESSAGE_THREAD,
        ROUTES.PROFILE,
        ROUTES.PROFILE_FEED,
        ROUTES.PROFILE_STORIES,
        ROUTES.PROFILE_STORIES_DETAIL,
        ROUTES.PROFILE_ACTIVITIES,
      ].includes(router.currentRoute.value.name as string)
    "
    @close="ui.closeMessageComposeModal"
  />

  <ContactSearchModal
    v-if="auth.isAuthenticated && ui.contactModalOpen"
    @close="ui.closeContactModal"
  />

  <div
    id="debugging"
    class="p-4 fixed z-[100] top-0-safe left-0-safe w-full pointer-events-none"
    v-if="appStore.visualViewportDebugging"
  >
    <div
      class="w-full p-4 space-y-4 font-semibold text-white rounded bg-blue-900/70"
    >
      <div>Top: {{ debuggingData.visualViewport?.topOffset }}</div>
      <div>Left: {{ debuggingData.visualViewport?.leftOffset }}</div>
      <div>Height: {{ debuggingData.visualViewport?.height }}</div>
      <div>
        Calculated Offset: {{ debuggingData.visualViewport?.calculatedOffset }}
      </div>
    </div>
  </div>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
