import useNetworkStore from "@/stores/resources/networks-store";
import usePostStore from "@/stores/resources/posts-store";
import useUserStore from "@/stores/resources/users-store";

import useAppConfig from "@/hooks/useAppConfig";
import useUiNotifications from "@/hooks/useUiNotifications";

export default function useNativeShare() {
  const postStore = usePostStore();
  const userStore = useUserStore();
  const networkStore = useNetworkStore();

  const { notify } = useUiNotifications();

  function sharePost(postId: number) {
    const post = postStore.postsById[postId];
    if (!post) return; // Error handling overhere.
    const user = userStore.usersById[post.user_id];
    if (!user) return; // Error handling overhere

    share({
      title: `Check out ${user.name}'s Allaxis post`,
      text: post.text,
      url: `${import.meta.env.VITE_BASE_URL}/web/post/${postId}`,
    });
  }

  function shareUserProfile(username: string) {
    const user = userStore.usersById[userStore.userIdByUsernameMap[username]];
    if (!user) return;

    share({
      title: `${user.name} (@${user.username}) - Allaxis`,
      text: `Check out ${user.name}'s Allaxis profile `,
      url: `${import.meta.env.VITE_BASE_SCOPE}/@${user.username}`,
    });
  }

  function sharePublicProfile(username: string) {
    const user = userStore.usersById[userStore.userIdByUsernameMap[username]];
    if (!user) return;

    share({
      title: `${user.name} (@${user.username}) - Allaxis`,
      text: `Check out ${user.name}'s Allaxis profile `,
      url: `${import.meta.env.VITE_BASE_URL}/@${user.username}`,
    });
  }

  function shareAxisPoint(networkSlug: string) {
    const network = networkStore.networksBySlug.get(networkSlug);
    if (!network) return;

    share({
      title: `${network.title} `,
      text: `Checkout ${network.title} on Allaxis`,
      url: `${import.meta.env.VITE_BASE_SCOPE}/ap/${network.slug}`,
    });
  }

  async function share(shareData: ShareData) {
    try {
      await window.navigator.share(shareData);
    } catch (err) {
      notify({
        title: "Something went wrong while attempting to share",
        type: "error",
      });
      console.log(err);
    }
  }

  return {
    sharePost,
    shareUserProfile,
    sharePublicProfile,
    shareAxisPoint,
  };
}
