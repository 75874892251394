<script setup lang="ts">
import { Button } from "@allaxis/ui";
import QRCodeVue3 from "qrcode-vue3";
import { computed, inject } from "vue";

import BaseModal from "@/components/BaseModal.vue";

import type { AppConfigType } from "@/types/appTypes";

const props = defineProps<PropsType>();
const emit = defineEmits<EventsType>();

const appConfig = inject<AppConfigType>("appConfig", {});

const isProd = import.meta.env.PROD;

const logoUrl = computed(() =>
  isProd ? `${import.meta.env.VITE_BASE_SCOPE}/favicon.ico` : "/favicon.ico"
);

type PropsType = {
  show?: boolean;
  username?: string;
};

type EventsType = {
  (e: "close"): void;
  (e: "deletePost"): void;
};
</script>

<template>
  <Transition
    enter-active-class="duration-300 ease-out md:duration-150"
    enter-from-class="-translate-y-[40rem] md:opacity-0 md:translate-y-5"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="duration-300 ease-out md:duration-150"
    leave-from-class="translate-y-0 opacity-100"
    leave-to-class="-translate-y-[40rem] md:opacity-0 md:translate-y-5"
  >
    <BaseModal
      class="fixed top-0 left-0 z-50 transition-all transform-gpu"
      modal-class="max-w-[28rem]"
      header-title="User QR Code"
      mobile-position="center"
      v-show="props.show"
      @close="emit('close')"
    >
      <div class="flex flex-col">
        <div class="flex justify-center p-4">
          <QRCodeVue3
            v-if="props.username"
            :value="`${appConfig.client_base_url}/@${props.username}`"
            myclass="p-2 rounded-lg bg-white"
            :image="logoUrl"
            :imageOptions="{ margin: 10 }"
            :dots-options="{
              type: 'rounded',
              gradient: {
                type: 'linear',
                colorStops: [
                  { offset: 0, color: '#2F007A' },
                  { offset: 0.5, color: '#06007A' },
                ],
              },
            }"
            :cornersSquareOptions="{ type: 'rounded', color: 'black' }"
          />
        </div>
        <Button text="Close" centered @click="emit('close')" />
      </div>
    </BaseModal>
  </Transition>
</template>
