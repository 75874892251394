import { acceptHMRUpdate, defineStore } from "pinia";
import { registerSW } from "virtual:pwa-register";
import { ref } from "vue";

import useExplorePageStore from "@/stores/activities/explore-page-store";
import useHomePageStore from "@/stores/activities/home-page-store";
import useUserSuggestionStore from "@/stores/common/user-suggestion-store";
import useBookmarkStore from "@/stores/resources/bookmarks-store";
import useFollowStore from "@/stores/resources/follow-store";
import useMessagesStore from "@/stores/resources/messages-store";
import useNetworkStore from "@/stores/resources/networks-store";
import useChatStore from "@/stores/resources/new-chat-store";
import useNotificationStore from "@/stores/resources/notifications-store";
import usePostStore from "@/stores/resources/posts-store";
import useReactionsStore from "@/stores/resources/reactions-store";
import useUserStore from "@/stores/resources/users-store";

import useUiNotifications from "@/hooks/useUiNotifications";

import useOverlayManager from "./overlay-manager";

import {
  loadAppInitialState,
  type InitialRequestData,
} from "@/services/app-service";
import type { BeforeInstallPromptEvent } from "@/types/appTypes";

const useAppStore = defineStore("app-store", function () {
  const hasLoadedInitialState = ref(true);

  const visualViewportDebugging = ref(false);

  const explorePageStore = useExplorePageStore();
  const homePageStore = useHomePageStore();

  const bookmarkStore = useBookmarkStore();
  const chatStore = useChatStore();
  const followStore = useFollowStore();
  const messageStore = useMessagesStore();
  const networkStore = useNetworkStore();
  const notificationStore = useNotificationStore();
  const postStore = usePostStore();
  const reactionStore = useReactionsStore();
  const userStore = useUserStore();

  const overlayManager = useOverlayManager();

  const userSuggestionStore = useUserSuggestionStore();

  const { notify } = useUiNotifications();

  async function parseInitialState(data: InitialRequestData) {
    const {
      users,
      reactions,
      posts,
      notifications,
      networks,
      messages,
      follows,
      followers,
      chats,
      bookmarks,
      suggestions,
    } = data.resources;

    await new Promise((resolve) => {
      bookmarkStore.parseBookmarks(bookmarks);
      chatStore.parseChats(chats);
      Object.entries(
        messages.reduce(function (acc, curr) {
          const nextAcc = acc;
          if (curr.chat_id) {
            nextAcc[curr.chat_id] ||= [];
            nextAcc[curr.chat_id].push(curr.id);
          }
          return nextAcc;
        }, {} as Record<number, Array<number>>)
      ).forEach(([chatId, messages]) => {
        chatStore.setMessageIdsbyChatId(parseInt(chatId), messages);
      });
      followStore.parseFollowings(follows);
      followStore.parseFollowers(followers);
      messageStore.parseMessages(messages);
      networkStore.parseNetworks(networks);
      notificationStore.parseNotificatiosFromRequestData({ notifications });
      postStore.parsePosts(posts);
      reactionStore.parseReactions(reactions);
      userStore.parseUsers(users);

      homePageStore.init(data);
      explorePageStore.init(data);

      userSuggestionStore.parseUserSuggestions(suggestions);

      resolve(undefined);
    });
  }

  async function performInitialFetch() {
    const { data, status } = await loadAppInitialState();
    if (status !== 200) return;
    console.log({
      initialState: data,
    });
    await parseInitialState(data);
    hasLoadedInitialState.value = true;
  }

  function toggleVisualViewportDebugging() {
    console.log({
      visualViewportDebugging: !visualViewportDebugging.value,
    });
    visualViewportDebugging.value = !visualViewportDebugging.value;
  }

  function setupServiceWorkerEvents() {
    const updateSW = registerSW({
      onNeedRefresh() {
        notify({
          title: "Update Available",
          type: "success",
          text: "App will be updated when restarted.",
          icon: "fas fa-arrow-down-to-line",
          duration: 10_000,
          primaryButtonHandler: async () => {
            await updateSW(true);
          },
          primaryButtonText: "Update",
        });
      },
      onOfflineReady() {
        notify({
          title: "App Installed Successfully.",
          type: "success",
        });
      },
    });
  }

  function setupEventListeners() {
    window.addEventListener("beforeinstallprompt", (e) => {
      overlayManager.showPwaInstallModal(e);
      e.preventDefault();
      console.log(`'beforeinstallprompt' event was fired.`);
    });

    window.addEventListener("appInstalled", function () {
      notify({
        title: "App installed",
        type: "success",
        icon: "fas fa-check",
        duration: 2000,
      });
    });

    document.addEventListener("touchstart", function (e) {
      // is not near edge of view, exit

      const event = e as unknown as MouseEvent;

      if (event.pageX > 10 && event.pageX < window.innerWidth - 10) return;

      // prevent swipe to navigate gesture

      e.preventDefault();
    });
  }

  return {
    hasLoadedInitialState,
    performInitialFetch,
    toggleVisualViewportDebugging,
    visualViewportDebugging,
    setupEventListeners,
    setupServiceWorkerEvents,
  };
});

export default useAppStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}
