<script lang="ts" setup>
import { ProfilePhoto } from "@allaxis/ui";
import { onMounted } from "vue";

import useExplorePageStore from "@/stores/activities/explore-page-store";

import useAsset from "@/hooks/useAsset";

import BaseModal from "./BaseModal.vue";

import { ROUTES } from "@/router";

const emit = defineEmits<EmitTypes>();

const explorePageStore = useExplorePageStore();

const asset = useAsset();

function handleClosetap(event: MouseEvent | TouchEvent) {
  event.stopPropagation();
  emit("close");
}

function handleNavigation(navigationCallback: (...params: any) => void) {
  navigationCallback();
  emit("close");
}

onMounted(function () {
  explorePageStore.loadPageData();
});

type EmitTypes = {
  (e: "close"): void;
};
</script>

<template>
  <div
    @click="handleClosetap"
    class="fixed top-0 left-0 z-50 flex flex-col items-center justify-start w-full h-full glass-blur bg-dynamic-neutral-dark bg-tint-opacity-10"
  >
    <BaseModal
      @close="handleClosetap"
      header-title="Contacts"
      mobile-position="top"
    >
      <div class="max-h-full h-96">
        <ul class="max-h-full p-2 space-y-2 overflow-y-scroll">
          <template v-for="(user, key) in explorePageStore.users" :key="key">
            <RouterLink
              :to="{
                name: ROUTES.MESSAGE_THREAD,
                params: { username: user.username },
              }"
              custom
              v-slot="{ navigate }"
            >
              <li
                class="flex items-center p-2 space-x-3 text-base font-normal leading-6 transition duration-150 ease-in-out rounded-md cursor-pointer bg-dynamic-neutral-light dark:bg-dynamic-neutral-900 overflow-x-clip hover:bg-dynamic-neutral-100 dark:hover:bg-dynamic-neutral-800 group hover:text-dynamic-neutral-900 dark:hover:text-dynamic-neutral-200 focus:outline-none focus:text-dynamic-neutral-900 dark:focus:text-dynamic-neutral-200"
                @click="() => handleNavigation(navigate)"
              >
                <ProfilePhoto
                  size="sm"
                  :image-url="
                    asset(
                      'profile/small/' + user.profile_photo?.filename,
                      !!user.profile_photo
                    )
                  "
                  :placeholder-text="user.acronym"
                />
                <div class="flex flex-col flex-grow">
                  <span
                    class="text-dynamic-neutral-700 dark:text-dynamic-neutral-200"
                    >{{ user.name }}</span
                  >
                  <span
                    class="text-dynamic-neutral-500 dark:text-dynamic-neutral-400"
                    >@{{ user.username }}</span
                  >
                </div>
              </li>
            </RouterLink>
          </template>
        </ul>
      </div>
    </BaseModal>
  </div>
</template>
@/stores/activities/explore-page-store
