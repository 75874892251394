import axios from "axios";
import { computed, ref } from "vue";

import api from "@/services/api";
import type { VaporUploadedFile } from "@/types/appTypes";

type VaporStoreOptionsType = {
  bucket?: string;
  contentType?: string;
  expires?: string;
  progress?: (_: number) => number;
};

type VaporResponseObject = VaporUploadedFile & {
  headers: Record<string, string>;
  [key: string]: unknown;
};

export default function useVaporUpload() {
  const fileCount = ref(0);
  const filesUploaded = ref(0);
  const currentFileUploadProgress = ref(0);

  const progress = computed(() => {
    const maxProgressPoint = fileCount.value * 100;
    return (
      ((filesUploaded.value * 100 + currentFileUploadProgress.value) * 100) /
      maxProgressPoint
    );
  });

  async function upload(file: File, options: VaporStoreOptionsType = {}) {
    const response = await api.post<VaporResponseObject>(
      "/vapor/signed-storage-url",
      {
        bucket: options.bucket || "",
        content_type: options.contentType || file.type,
        expires: options.expires || "",
      },
      {
        baseURL: import.meta.env.VITE_BASE_URL,
      }
    );
    // This is the fix for the headers. Instance just for the S3 PUT

    const headers = {
      ...response.data.headers,
    };

    delete headers["Host"];

    const instance = axios.create({
      withCredentials: false,
    });
    instance.defaults.headers.common = {};
    await instance.put(response.data.url, file, {
      headers,
      onUploadProgress: (progressEvent) => {
        if (!progressEvent.total) return;
        currentFileUploadProgress.value =
          (progressEvent.loaded / progressEvent.total) * 100;
        options.progress
          ? options.progress(progressEvent.loaded / progressEvent.total)
          : null;
      },
    });

    response.data.extension = file.name.split(".").pop();

    return response.data;
  }

  return {
    upload,
    progress,
  };
}
