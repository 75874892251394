import useAuthStore from "@/stores/common/auth";

import ROUTES from "../constants";

import AuthenticationRoute from "./authentication-route";
import Bookmark from "./bookmark-route";
import ExploreRoute from "./explore-route";
import HomeRoute from "./home-route";
import MessagesRoute from "./messages-route";
import NetworkPostComposeRoute from "./network-post-compose-route";
import NetworkRoutes from "./network-routes";
import NotificationsRoute from "./notification-route";
import OnboardingRoute from "./onboarding-route";
import People from "./people-route";
import PostComposeRoute from "./post-compose-route";
import PostDetail from "./post-detail-route";
import PostReplyComposeRoute from "./post-reply-compose-route";
import ProfileRoute from "./profile-route";
import SettingRoute from "./settings-route";
import TagRoute from "./tag-route";
import TrendsRoute from "./trends-route";
// ROUTE IMPORTS

const DevelopmentSettings = () => import("@/apps/DeveloperSettings.vue");

const ProfileRedirect = {
  path: "/profile",
  redirect: () => {
    const auth = useAuthStore();
    if (!auth.user) return { name: ROUTES.HOME };
    return {
      name: ROUTES.PROFILE,
      params: {
        username: auth.user.username,
      },
    };
  },
};

export default [
  HomeRoute,
  ProfileRedirect,
  AuthenticationRoute,
  ProfileRoute,
  MessagesRoute,
  ExploreRoute,
  SettingRoute,
  TrendsRoute,
  NetworkPostComposeRoute,
  ...NetworkRoutes,
  PostReplyComposeRoute,
  PostDetail,
  Bookmark,
  People,
  NotificationsRoute,
  TagRoute,
  OnboardingRoute,
  PostComposeRoute,
  // ROUTE EXPORTS

  {
    name: "dev",
    path: "/dev",
    component: DevelopmentSettings,
  },
];
