import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, ref, type Ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import useBookmarkStore from "@/stores/resources/bookmarks-store";
import useNotificationStore from "@/stores/resources/notifications-store";
import usePostStore from "@/stores/resources/posts-store";
import useReactionsStore from "@/stores/resources/reactions-store";
import useUserStore from "@/stores/resources/users-store";

import usePageState from "@/hooks/usePageState";
import usePostActions from "@/hooks/usePostActions";
import useUiNotifications from "@/hooks/useUiNotifications";

import { ROUTES } from "@/router";
import { loadPostDetailsPageData } from "@/services/page-service";
import { deletePost } from "@/services/posts-service";
import type { PostPayloadType } from "@/types/appTypes";
import callOptional from "@/utils/callOptional";

const usePostDetailPageStore = defineStore("postDetailPage", () => {
  const { fetchPageData, loading, errorWhileLoading } = usePageState();
  const { createPost, createPostThread } = usePostActions();

  const { notify } = useUiNotifications();

  const router = useRouter();

  const postStore = usePostStore();
  const reactionStore = useReactionsStore();
  const bookmarkStore = useBookmarkStore();
  const notificationStore = useNotificationStore();
  const userStore = useUserStore();
  /**
   * @example
   * [[postId, [...replyIds]]]
   */
  const replyIdsByPostId = ref<Map<number, Set<number>>>(new Map()); // { postId: [replyId, replyId] }

  const replies = computed(() => {
    const postId = useRoute().params.postId;
    return Array.from(
      replyIdsByPostId.value.get(parseInt(postId as string)) || new Set()
    )
      .map((id) => postStore.postsById[id as number])
      .map((post) => {
        const user = userStore.usersById[post.user_id];
        if (!user) return null;
        return {
          ...post,
          user,
          links: Array.from(postStore.linkIdsByPostId.get(post.id) || [])
            .map((id) => postStore.linksById.get(id))
            .filter((link) => link !== undefined) as Link[],
          isBookmarked: !!bookmarkStore.postBookmarksById.get(post.id),
          hasReacted: !!reactionStore.userRectionsByPostId.has(post.id),
        };
      })
      .filter((post) => post !== null);
  });

  const targetPost = computed(() => {
    const postId = useRoute().params.postId;
    const post = postStore.postsById[parseInt(postId as string)];
    if (!post) return null;
    const user = userStore.usersById[post.user_id];
    if (!user) return null;

    return {
      ...post,
      user: user,
      links: Array.from(postStore.linkIdsByPostId.get(post.id) || [])
        .map((id) => postStore.linksById.get(id))
        .filter((link) => link !== undefined) as Link[],
      reply_to: post.reply_to_id ? postStore.postsById[post.reply_to_id] : null,
      isBookmarked: !!bookmarkStore.postBookmarksById.get(post.id),
      hasReacted: !!reactionStore.userRectionsByPostId.has(post.id),
    };
  });

  /**
   * Set post details page data.
   * @param post Post
   * @param replies  Post replies
   */
  function setPostDetailsPageData(post: Post, replies: Array<Post>) {
    const currentPostReplyIds =
      replyIdsByPostId.value.get(post.id) ?? new Set();
    const newPostReplyIds = replies.map((reply) => reply.id);

    const sortedPostReplyIds = [
      ...currentPostReplyIds,
      ...newPostReplyIds,
    ].sort((a, b) =>
      (postStore.postsById[b].created_at ?? "") >
      (postStore.postsById[a].created_at ?? "")
        ? 1
        : -1
    );

    replyIdsByPostId.value.set(post.id, new Set(sortedPostReplyIds));
  }

  /**
   * Parse post details page data.
   * @param post Post
   * @param replies Post replies
   */
  function parsePostDetailsPageData(
    post: Post,
    replies: Array<Post>,
    reactions: Array<Reaction>
  ) {
    postStore.parsePosts([post, ...replies]);
    reactionStore.parseReactions(reactions);
    setPostDetailsPageData(post, replies);
  }

  async function createReply(
    { text, photos }: PostPayloadType,
    postId: number
  ) {
    try {
      await createPost({
        text,
        parentPostId: postId,
        photos,
        async onSuccess(post, reply) {
          // const [post, reply] = posts as Post[];
          setPostDetailsPageData(postStore.postsById[postId], [reply as Post]);
          notify({
            title: "Reply submitted successfully",
            type: "success",
          });
        },
      });
    } catch (e) {
      notify({
        title: "Reply submission error",
        text: "Something went wrong while submitting reply",
        type: "error",
      });
    }
  }

  function publishPostThreadAsReplies(
    {
      parentPostId,
      posts,
    }: {
      parentPostId: string;
      posts: Array<{ text: string; photos?: Array<Record<string, string>> }>;
    },
    onComplete?: () => void
  ) {
    const postId = parseInt(parentPostId);
    createPostThread({
      posts,
      parentPostId: postId,
      onSuccess(...result) {
        const [_, ...replies] = result;
        setPostDetailsPageData(
          postStore.postsById[postId],
          replies as Array<Post>
        );
        notify({
          title: "Reply submitted successfully",
          type: "success",
        });
        callOptional(onComplete, result);
      },
    });
  }

  async function removePost(postId: number) {
    try {
      const post = postStore.postsById[postId];
      if (!post) return;
      const user = userStore.usersById[post.user_id];
      if (!user) return;
      postStore.removeUserPost(user.username, postId);
      const {
        data: { user: updatedUser },
      } = await deletePost(postId);
      userStore.parseUsers([updatedUser]);
    } catch (e) {
      notify({
        title: "Post removal error",
        text: "Something went wrong while removing post",
        type: "error",
      });
    }
  }

  async function removeReply(postId: number, replyId: number) {
    try {
      const post = postStore.postsById[replyId];
      if (!post) return;
      const user = userStore.usersById[post.user_id];
      if (!user) return;
      postStore.removeUserPost(user.username, replyId);
      const {
        data: { user: updatedUser },
      } = await deletePost(replyId);
      userStore.parseUsers([updatedUser]);
      replyIdsByPostId.value.get(postId)?.delete(replyId);
    } catch (e) {
      notify({
        title: "Reply removal error",
        text: "Something went wrong while removing reply",
        type: "error",
      });
    }
  }

  async function handlePostSubmit(newPostText: string, onComplete: () => void) {
    if (newPostText) {
      await createReply(
        { text: newPostText },
        (targetPost as Ref<Post>).value.id
      );
      onComplete();
    }
  }

  async function loadPostAndReplies(pid?: string) {
    const postId = pid || useRoute().params.postId;
    await fetchPageData({
      async fetch() {
        await new Promise((resolve) => setTimeout(() => resolve(0), 1000));

        const { data } = await loadPostDetailsPageData(
          parseInt(postId as string)
        );

        const { post, replies, reactions } = data;

        notificationStore.parseNotificatiosFromRequestData(data);

        parsePostDetailsPageData(post, replies, reactions);
      },
    });
  }

  return {
    replies,
    post: targetPost,

    replyIdsByPostId,
    createReply,
    createThreadAsReplies: publishPostThreadAsReplies,
    removePost,
    removeReply,
    handlePostSubmit,

    loadPostAndReplies,

    loading,
    errorWhileLoading,

    navigateToReplyTo: (postId: number) =>
      router.push({ name: ROUTES.POST_DETAIL, params: { postId } }),
    toggleBookmark: (postId: number) => bookmarkStore.toggleBookmark(postId),
    toggleReaction: (postId: number) => reactionStore.toggleReaction(postId),
    deletePost: (postId: number) => {
      removePost(postId);
    },
    deleteReply: (postId: number, parentPostId: number) => {
      removeReply(parentPostId, postId);
    },
  };
});

export default usePostDetailPageStore;

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePostDetailPageStore, import.meta.hot)
  );
}
