<script lang="ts" setup>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

import ContextMenu from "@/components/ContextMenu.vue";

import useAuthStore from "@/stores/common/auth";
import useOverlayManager from "@/stores/common/overlay-manager";

import useAppConfig from "@/hooks/useAppConfig";

import InstallModal from "./InstallModal.vue";
import PostComposeModal from "./PostComposeModal.vue";
import PostDeleteModal from "./PostDeleteModal.vue";
import UserBottomSheet from "./UserBottomSheet.vue";
import UserQrCodeModal from "./UserQrCodeModal.vue";

import { ROUTES } from "@/router";
import type { BeforeInstallPromptEvent } from "@/types/appTypes";

const authStore = useAuthStore();
const overlayManagerStore = useOverlayManager();
const appConfig = useAppConfig();

const currentContextMenu =
  ref<typeof overlayManagerStore.contextMenuConfig>(null);
const router = useRouter();
watch(
  () => overlayManagerStore.contextMenuConfig,
  function (val) {
    if (val) {
      currentContextMenu.value = val;
    }
  }
);
</script>
<template>
  <slot></slot>

  <Transition
    enter-active-class="duration-300 ease-in-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-300 ease-out"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-show="overlayManagerStore.isOverlayOpen"
      @click="() => overlayManagerStore.reset()"
      class="fixed inset-0 z-50 bg-black/20 dark:bg-black/70 glass-blur"
      id="overlay-backdrop"
    ></div>
  </Transition>

  <UserBottomSheet
    id="user-bottom-sheet"
    class="fixed z-50 md:hidden transform-gpu"
    @logout-tap="authStore.sessionEnd"
    @close="overlayManagerStore.reset"
    :me="authStore.user"
    :show="!!(overlayManagerStore.isUserBottomSheetOpen && authStore.user)"
  />

  <ContextMenu
    :show="overlayManagerStore.isContextMenuOpen"
    :config="currentContextMenu"
    @close="overlayManagerStore.reset"
  />

  <PostComposeModal
    v-show="
      authStore.isAuthenticated &&
      overlayManagerStore.isPostComposeModalOpen &&
      [
        ROUTES.HOME, ROUTES.POST_DETAIL, 
        ROUTES.PROFILE, 
        ROUTES.PROFILE_FEED, 
        ROUTES.PROFILE_ACTIVITIES, 
        ROUTES.PROFILE_STORIES, 
        ROUTES.PROFILE_STORIES_DETAIL,
        ROUTES.NETWORK,
        ROUTES.NETWORK_FEED,
      ].includes(router.currentRoute.value.name as string)
    "
    @close="overlayManagerStore.reset"
  />

  <InstallModal
    v-show="overlayManagerStore.isPwaInstallModalOpen"
    :install-event="(overlayManagerStore.pwaInstallEvent as BeforeInstallPromptEvent)"
    @close="overlayManagerStore.reset"
  />

  <PostDeleteModal
    :show="overlayManagerStore.isPostDeleteModalOpen"
    @delete-post="overlayManagerStore.postDeleteCallback"
    @close="overlayManagerStore.reset"
  />

  <UserQrCodeModal
    v-if="appConfig"
    :show="overlayManagerStore.isUserQrCodeModalOpen"
    :username="overlayManagerStore.usernameForQrCode"
    @close="overlayManagerStore.reset"
  />
</template>
