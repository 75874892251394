import { acceptHMRUpdate, defineStore } from "pinia";
import { computed } from "vue";

import useChatStore from "@/stores/resources/new-chat-store";
import useUserStore from "@/stores/resources/users-store";

import useAsset from "@/hooks/useAsset";
import useOtherUser from "@/hooks/useOtherUser";
import usePageState from "@/hooks/usePageState";

import useAuthStore from "../common/auth";
import useMessagesStore from "../resources/messages-store";

import { sortIterable } from "@/utils/sorting";

const useMessagesPageStore = defineStore("messagesPage", () => {
  const { fetchPageData, loading, errorWhileLoading } = usePageState();
  const {
    fetchPageData: fetchChatData,
    loading: loadingChat,
    errorWhileLoading: errorWhileLoadingChat,
  } = usePageState();

  const chatStore = useChatStore();
  const userStore = useUserStore();
  const messageStore = useMessagesStore();
  const currentUser = useAuthStore().user;

  const asset = useAsset();

  const chats = computed(() =>
    sortIterable(
      Array.from([...chatStore.chatsById.values()]),
      "desc",
      "last_message_at"
    )
      .map((chat) => {
        let header = chat.title || "No title";
        let preview = "No message";
        let iconText = chat.title ? chat.title.charAt(0) : "😜";
        let imageUrl = "";
        let lastMessageAt = "";

        const message = messageStore.messagesById.get(
          chat.last_message_id as number
        );

        if (message !== undefined) {
          const name =
            currentUser.id === message.from_user_id
              ? "You"
              : userStore.usersById[message.from_user_id].name;
          preview = `${name}: ${message.text}`;
          lastMessageAt = message.created_at_readable as string;
        }

        if (chat.dm_user_ids && message) {
          const [user1, user2] = chat.dm_user_ids
            .split("_")
            .map((id) => userStore.usersById[parseInt(id)]);

          const otherUser = useOtherUser(user1, user2);

          if (otherUser) {
            const user = userStore.usersById[otherUser.id];
            header = user.name;
            iconText = user.acronym;
            preview ??= message?.text;
            imageUrl = asset(
              "profile/small/" + otherUser.profile_photo?.filename,
              !!otherUser.profile_photo
            );
          }
        }

        if (
          chat.dm_user_ids &&
          chat.dm_user_ids
            .split("_")
            .every((id) => parseInt(id) === currentUser.id) &&
          message
        ) {
          const user = currentUser;

          header = user.name;
          iconText = user.acronym;
          preview = message?.text;
          imageUrl = asset(
            "profile/small/" + user.profile_photo?.filename,
            !!user.profile_photo
          );
        }

        return {
          ...chat,
          header,
          preview,
          iconText,
          lastMessageAt,
          imageUrl,
        };
      })
      .filter((c) => c !== undefined)
  );

  async function loadChats() {
    await fetchPageData({
      async fetch() {
        await new Promise((r) => setTimeout(r, 1000));
        await chatStore.getChats();
      },
    });
  }

  async function loadGroupChat(chatId: number) {
    await fetchChatData({
      async fetch() {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await chatStore.getChat(chatId);
        await chatStore.getChatMessages(chatId);
      },
    });
  }

  async function loadDirectChat(username: string) {
    await fetchChatData({
      async fetch() {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await chatStore.getDirectChat(username);
        const chatId = chatStore.usernameChatIdMap.get(username);

        if (chatId) {
          await chatStore.getChatMessages(chatId);
        }
      },
    });
  }

  function sendMessage() {}

  return {
    chats,
    loadChats,
    loadGroupChat,
    loadDirectChat,
    loading,
    loadingChat,
    errorWhileLoading,
    errorWhileLoadingChat,
    sendMessage: chatStore.sendMessage,
    sendMessageToNewChat: chatStore.sendFirstChatMessage,
  };
});

export default useMessagesPageStore;

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMessagesPageStore, import.meta.hot)
  );
}
