<script lang="ts" setup>
import { ProfilePhoto } from "@allaxis/ui";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import InfiniteScroll from "@/components/InfiniteScroll.vue";
import MobileNav from "@/components/MobileNav.vue";
import NavBar from "@/components/NavBar.vue";
import TitleBar from "@/components/TitleBar.vue";

import useAuthStore from "@/stores/common/auth";
import useOverlayManager from "@/stores/common/overlay-manager";
import useUiStore from "@/stores/common/ui";

import useAsset from "@/hooks/useAsset";

import PullToRefresh from "./PullToRefresh.vue";

import ActionContainer from "@/containers/ActionContainer.vue";
import AppNotificationContainer from "@/containers/AppNotificationContainer.vue";
import { ROUTES } from "@/router";

const props = withDefaults(defineProps<PropTypes>(), {
  hasSideBarMenuButton: false,
  hasBackButton: false,
  hasMobileBackButton: false,
  hasNavBar: true,
  hasTitleBar: true,
  hasMobileNav: true,
  showTopDivider: true,
  hasPageLoaded: false,
  pullDown: () => {
    console.log("RELOADING PAGE");
    window.location.reload();
  },
});
const ui = useUiStore();
const router = useRouter();
const auth = useAuthStore();

const overlayManagerStore = useOverlayManager();
const asset = useAsset();
const route = useRoute();

const primaryButton = computed(() => {
  const defaultConfig = {
    text: "Compose",
    icon: "far fa-pen-clip",
    clickHandler: () => overlayManagerStore.showPostComposeModal(),
  };

  if (
    props.primaryButton &&
    typeof props.primaryButton.clickHandler === "function"
  ) {
    return props.primaryButton;
  }

  return [
    ROUTES.HOME,
    ROUTES.POST_DETAIL,
    ROUTES.PROFILE,
    ROUTES.PROFILE_FEED,
    ROUTES.NETWORK,
    ROUTES.NETWORK_FEED,
  ].includes(route.name as string)
    ? defaultConfig
    : undefined;
});

type FabButtonConfigType = {
  text: string;
  icon: string;
  class?: string;
  clickHandler: () => void;
};

type PropTypes = {
  title?: string;
  hasSideBarMenuButton?: boolean;
  hasBackButton?: boolean;
  hasMobileBackButton?: boolean;
  hasNavBar?: boolean;
  hasTitleBar?: boolean;
  hasMobileNav?: boolean;
  showTopDivider?: boolean;
  hasPageLoaded?: boolean;
  primaryButton?: FabButtonConfigType;
  secondaryButton?: FabButtonConfigType;
  scrollTopHandler?: () => void;
  scrollBottomHandler?: () => void;
  pullDown?: () => void | Promise<void>;
  pullDownToRefreshPlaceholderText?: string;
  pullDownToRefreshText?: string;
};

function handleMobileLeftButtonTap() {
  if (props.hasMobileBackButton) {
    router.go(-1);
  } else {
    ui.toggleSidebar();
  }
}
</script>

<template>
  <template v-if="auth.user">
    <PullToRefresh
      v-if="pullDown"
      @pull="() => pullDown()"
      :placeholder-text="pullDownToRefreshPlaceholderText"
      :text="pullDownToRefreshText"
    />

    <div class="sticky top-0 z-40 w-full">
      <slot name="header" :toggleSidebar="ui.toggleSidebar">
        <NavBar
          @left-button-tap="ui.toggleSidebar"
          :show-divider="showTopDivider"
          @right-button-tap="() => $router.push({ name: ROUTES.SETTINGS })"
          right-icon="fa-sliders text-md"
        />
        <TitleBar
          @left-button-tap="handleMobileLeftButtonTap"
          :left-icon="props.hasMobileBackButton ? 'fa-arrow-left' : 'fa-bars'"
          :title="title || ''"
          :show-divider="showTopDivider"
        >
          <template v-if="!hasMobileBackButton" #leading-slot>
            <a
              @click="overlayManagerStore.showUserBottomSheet()"
              class="flex flex-row items-center justify-start w-16 pl-2 cursor-pointer xs:w-20"
            >
              <ProfilePhoto
                size="xs"
                :placeholder-text="auth.user.acronym"
                :image-url="
                  asset(
                    'profile/small/' + auth.user.profile_photo?.filename,
                    !!auth.user.profile_photo
                  )
                "
              />
            </a>
          </template>

          <template v-if="!title || title == ''" #default>
            <span class="flex flex-row items-center justify-center space-x-3">
              <img
                class="w-6 h-6 drop-shadow-md"
                src="../assets/logo.svg"
                alt=""
              />
              <h4 class="text-lg xs:text-2xl">Allaxis</h4>
            </span>
          </template>

          <template #trailing-slot>
            <a
              @click="overlayManagerStore.showUserBottomSheet()"
              class="flex flex-row items-center justify-end w-16 cursor-pointer xs:w-20"
            >
              <ProfilePhoto
                size="xs"
                :placeholder-text="auth.user.acronym"
                :image-url="
                  asset(
                    'profile/small/' + auth.user.profile_photo?.filename,
                    !!auth.user.profile_photo
                  )
                "
              />
            </a>
          </template>
        </TitleBar>
      </slot>
    </div>

    <InfiniteScroll
      @hit-top="() => props.scrollTopHandler && props.scrollTopHandler()"
      @hit-bottom="
        () => props.scrollBottomHandler && props.scrollBottomHandler()
      "
      :has-page-loaded="props.hasPageLoaded"
      :has-page-top-observer="typeof props.scrollTopHandler === 'function'"
      :has-page-bottom-observer="
        typeof props.scrollBottomHandler === 'function'
      "
      root="#main"
    >
      <div
        id="page"
        class="flex flex-col items-center w-full min-h-screen-minus-nav text-dynamic-neutral-700 dark:text-dynamic-neutral-200"
      >
        <slot></slot>
      </div>
    </InfiniteScroll>

    <div class="sticky bottom-0 z-40 w-full allaxis-app-bottom-tape">
      <ActionContainer
        :primary-button="primaryButton"
        :secondary-button="props.secondaryButton"
      />

      <AppNotificationContainer />

      <slot name="footer">
        <MobileNav />
      </slot>
    </div>
  </template>
</template>
