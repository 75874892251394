import { useLocalStorage } from "@vueuse/core";
import { createRouter, createWebHistory } from "vue-router";

import useAuthStore from "@/stores/common/auth";
import useOverlayManager from "@/stores/common/overlay-manager";

import usePlatform from "@/hooks/usePlatformFeatureCheck";

import { ROUTES } from ".";

import routes from "@/router/routes";

let isNavigatingToPreviousPage = false;

window.addEventListener("popstate", function () {
  isNavigatingToPreviousPage = true;
});

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  const query = to.query;
  const authStore = useAuthStore();
  if (query["user-qr"] && authStore.isAuthenticated) {
    const overlayManager = useOverlayManager();
    overlayManager.showUserQrCodeModal(authStore.user.username);
    delete query["user-qr"];
  }

  return true;
});

router.beforeEach((to, _from, next) => {
  const authStore = useAuthStore();
  const onboardingComplete = useLocalStorage("onboarding-complete", false);

  const { isRunningAsPWA } = usePlatform();

  if (onboardingComplete.value && to.name === ROUTES.ONBOARDING)
    return next({ name: ROUTES.HOME });
  if (!onboardingComplete.value && to.name !== ROUTES.ONBOARDING)
    return next({ name: ROUTES.ONBOARDING });
  if (to.matched.some((r) => r.meta.secure) && !authStore.isAuthenticated) {
    if (!isRunningAsPWA) {
      window.location.replace(import.meta.env.VITE_BASE_URL + "/acceso");
    }
    return next({ name: ROUTES.LOGIN });
  }
  if (to.name === ROUTES.LOGIN && authStore.isAuthenticated) {
    return next({ name: ROUTES.HOME });
  }

  return next();
});

router.beforeEach(function () {
  const overlayManager = useOverlayManager();

  if (overlayManager.isOverlayOpen && isNavigatingToPreviousPage) {
    isNavigatingToPreviousPage = false;
    overlayManager.reset();
    return false;
  }
  return true;
});

export { default as ROUTES } from "@/router/constants";

export default router;
