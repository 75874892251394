<script lang="ts" setup>
import { ref } from "vue";

const props = withDefaults(defineProps<PropsType>(), {
  mobilePosition: "top",
  modalClass: "",
});
const emit = defineEmits<EventsType>();

const modalRef = ref();

defineExpose({
  modalRef,
});

type PropsType = {
  headerTitle?: string;
  mobilePosition: "top" | "bottom" | "center";
  modalClass?: string;
};

type EventsType = {
  (e: "close", event: MouseEvent): void;
};
</script>
<template>
  <div
    :class="{
      'top-1-safe aspect-landscape:md:top-1-safe md:top-44-safe lg:!top-44-safe':
        props.mobilePosition === 'top',
      'h-full top-0': props.mobilePosition === 'center',
      'bottom-1-safe aspect-landscape:md:bottom-1-safe md:bottom-44-safe lg:!bottom-44-safe':
        props.mobilePosition === 'bottom',
    }"
    class="fixed flex flex-col items-center justify-center w-full px-1 aspect-landscape:md:left-0 md:left-12 lg:left-0"
  >
    <div
      @click.stop
      :class="props.modalClass"
      ref="modalRef"
      class="py-4 px-6 w-full max-w-screen-sm rounded-md !shadow-black/80 !shadow-2xl bg-dynamic-neutral-light ui-item dark:bg-dynamic-neutral-900 bg-tint-opacity-100"
    >
      <div
        v-if="props.headerTitle"
        class="flex flex-row justify-between pb-2 border-b aspect-landscape:hidden aspect-landscape:lg:flex border-dynamic-neutral-200 dark:border-dynamic-neutral-700 dark:text-dynamic-neutral-200 text-dynamic-neutral-800"
      >
        <slot name="header-leading-space">
          <span class="flex-grow p-2"></span>
        </slot>
        <h4 class="p-2 font-semibold">{{ props.headerTitle }}</h4>
        <slot name="header-trailing-space">
          <span class="flex items-center justify-end flex-grow p-2">
            <button @click="($event) => emit('close', $event)">
              <i class="far fa-xmark"></i>
            </button>
          </span>
        </slot>
      </div>

      <slot />
    </div>
  </div>
</template>
