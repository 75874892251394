import { ROUTES } from "@/router";

const PeopleApp = () => import("@/apps/PeopleApp.vue");
const UserFollowing = () => import("@/partials/UserFollowings.vue");
const UserFollowers = () => import("@/partials/UserFollowers.vue");
const UserContacts = () => import("@/partials/UserContacts.vue");

export default {
  name: ROUTES.PEOPLE,
  path: "/people",
  component: PeopleApp,
  meta: { secure: true },
  children: [
    {
      name: ROUTES.PEOPLE_FOLLOWING,
      path: "following",
      component: UserFollowing,
    },

    {
      name: ROUTES.PEOPLE_FOLLOWERS,
      path: "followers",
      component: UserFollowers,
    },

    {
      name: ROUTES.PEOPLE_CONCTACTS,
      path: "contacts",
      component: UserContacts,
    },
  ],

  redirect: () => ({
    name: ROUTES.PEOPLE_FOLLOWING,
  }),
};
