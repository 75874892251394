<script setup lang="ts">
import { computed } from "vue";

import useAppConfig from "@/hooks/useAppConfig";
import useAsset from "@/hooks/useAsset";

const props = defineProps<PropTypes>();

const asset = useAsset();

const appConfig = useAppConfig();
const allaxisWebUrl = appConfig.web_base_url;

const linkBaseUrl = computed(() =>
  props.link?.url.replace(
    /(([a-zA-Z0-9]+:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,4})(:[0-9]+)?((\/.{0,14}).*)?)/g,
    "$4"
  )
);

type PropTypes = {
  link?: Link;
};
</script>

<template>
  <a
    v-if="allaxisWebUrl"
    :href="`${allaxisWebUrl}/away/${link?.id}`"
    target="_blank"
    rel="noreferrer"
    @click.stop
    class="relative flex flex-col overflow-hidden bg-opacity-0 rounded-md border-dynamic-neutral-200 dark:border-dynamic-neutral-700 bg-nuetral-500 hover:bg-opacity-10"
  >
    <img
      v-if="props.link?.image_filename"
      :src="asset('links/hd/' + props.link?.image_filename, !!props.link)"
      class="flex-shrink-0 object-cover md:w-[unset]"
      loading="lazy"
    />

    <div
      class="z-10 flex flex-col items-center w-full p-3 space-y-1 text-sm break-all bg-opacity-100 bg-theme-500"
    >
      <p
        class="font-bold text-center break-normal line-clamp-2 text-theme-aware-neutral-500"
      >
        {{ props.link?.title }}
      </p>
      <p class="flex-row flex-grow text-theme-aware-neutral-500">
        <i class="far fa-link"></i>&nbsp;
        <span>{{ linkBaseUrl }}</span>
      </p>

      <!-- <p
        class="hidden text-dynamic-neutral-600 dark:text-dynamic-neutral-400 xs:flex"
      >
        {{ props.link?.description }}
      </p> -->
    </div>
  </a>
</template>
