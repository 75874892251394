<script lang="ts" setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

import BaseModal from "@/components/BaseModal.vue";
import PostCompose from "@/components/PostCompose.vue";

import useHomePageStore from "@/stores/activities/home-page-store";
import useNetworkPageStore from "@/stores/activities/network-page-store";
import usePostDetailPageStore from "@/stores/activities/post-detail-page-store";
import useProfilePageStore from "@/stores/activities/profile-page-store";

import { ROUTES } from "@/router";
import type { PostPayloadType } from "@/types/appTypes";
import type { PostDetailRouteType } from "@/types/routeTypes";

const emit = defineEmits<EmitTypes>();

const homePageStore = useHomePageStore();
const postDetailPageStore = usePostDetailPageStore();
const profilePageStore = useProfilePageStore();
const networkPageStore = useNetworkPageStore();

const route = useRoute() as PostDetailRouteType;

const baseModalRef = ref();

const modalHeight = computed(() => {
  if (baseModalRef.value?.modalRef?.clientHeight) {
    return baseModalRef.value?.modalRef?.clientHeight + "px";
  }

  return "28rem";
});

console.log({
  baseModalRef,
});

async function handlePostCreation(
  posts: Array<PostPayloadType>,
  onComplete: () => void
) {
  try {
    console.log({
      route,
    });
    switch (route.name) {
      case ROUTES.HOME:
        await homePageStore.createPostThread({ posts }, onComplete);
        break;
      case ROUTES.POST_DETAIL: {
        const parentPostId = route.params.postId as unknown as string;
        await postDetailPageStore.createThreadAsReplies(
          {
            parentPostId,
            posts,
          },
          onComplete
        );
        break;
      }
      case ROUTES.NETWORK:
      case ROUTES.NETWORK_FEED:
        await networkPageStore.createPostThread({ posts }, onComplete);
        break;
      default:
        await profilePageStore.createThread({ posts }, onComplete);
        break;
    }
  } catch (e) {
    console.log("POST COMPOSE MODAL: Something went wrong posting", e);
  } finally {
    onComplete();
    emit("close"); // Just being lazy here. Create another event...
  }
}

function handleClosetap(event: MouseEvent | PointerEvent) {
  event.stopPropagation();
  emit("close");
}

type EmitTypes = {
  (e: "close"): void;
};
</script>

<template>
  <Transition
    enter-active-class="duration-300 ease-out md:duration-150"
    :enter-from-class="`-translate-y-[${modalHeight}] md:opacity-0 md:translate-y-5`"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="duration-300 ease-out md:duration-150"
    leave-from-class="translate-y-0 opacity-100"
    :leave-to-class="`-translate-y-[${modalHeight}] md:opacity-0 md:translate-y-5`"
  >
    <BaseModal
      class="fixed top-0 left-0 z-50 transition-all transform-gpu"
      header-title="Compose"
      mobile-position="top"
      @close="handleClosetap"
      ref="baseModalRef"
    >
      <PostCompose use-large-textarea @publish="handlePostCreation" />
    </BaseModal>
  </Transition>
</template>
